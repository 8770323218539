import { useCallback, useRef } from 'react';
import { useRouter } from 'next/router';
import userService from '@/services/userService';
import toast from 'react-hot-toast';
import xw from 'xwind';
import { SETTINGS_PATH } from '@/common/constants/routes';
import { ESettingsTabs } from '@/domains/settings/constants/Tabs';
import { CompanyStore } from '@/stores/index';

export const useInviteOrgAccept = (): (() => void) => {
  const router = useRouter();
  const isInviteConfirmed = useRef(false);

  const { org: inviteOrg } = router.query;
  const isInviteLink = !!inviteOrg;

  return useCallback(() => {
    const confirmInvite = async () => {
      const organizationList = await userService.acceptOrganizationInvite();
      if (organizationList && organizationList.length) {
        const [organization] = organizationList;
        router.push(`${SETTINGS_PATH}/${ESettingsTabs.RESOURCES}`);
        CompanyStore.company = organization;
        toast(
          `You have been added to the organization ${organization.title}!`,
          {
            duration: 3000,
            style: xw`px-16 text-active`,
          },
        );
      }
    };

    if (isInviteLink && !isInviteConfirmed.current) {
      isInviteConfirmed.current = true;
      confirmInvite();
    }
  }, [isInviteLink, router]);
};
