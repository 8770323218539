import xw from 'xwind';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const AuthFormContainer = styled(motion.div)`
  ${xw`
    w-full
    flex flex-col items-center
    pb-6 pt-3 sm:pt-0
  `};

  @media (min-width: 640px) {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default AuthFormContainer;
