import { SVGAttributes } from 'react';

export function ParagraphIcon(
  props: SVGAttributes<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6086_139194)">
        <path
          d="M17.25 8.24976H0.75C0.335786 8.24976 0 8.58554 0 8.99976C0 9.41398 0.335786 9.74976 0.75 9.74976H17.25C17.6642 9.74976 18 9.41398 18 8.99976C18 8.58554 17.6642 8.24976 17.25 8.24976Z"
          fill="currentColor"
        />
        <path
          d="M17.25 3.00024H0.75C0.335786 3.00024 0 3.33603 0 3.75024C0 4.16446 0.335786 4.50024 0.75 4.50024H17.25C17.6642 4.50024 18 4.16446 18 3.75024C18 3.33603 17.6642 3.00024 17.25 3.00024Z"
          fill="currentColor"
        />
        <path
          d="M17.25 13.5H0.75C0.335786 13.5 0 13.8358 0 14.25C0 14.6642 0.335786 15 0.75 15H17.25C17.6642 15 18 14.6642 18 14.25C18 13.8358 17.6642 13.5 17.25 13.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6086_139194">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
