import React, { useCallback, useEffect, useMemo } from 'react';
import Image from 'next/image';
import { observer } from 'mobx-react-lite';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import xw from 'xwind';

import AuthStore from '@/stores/AuthStore';
import AuthModalStore from '@/stores/AuthModalStore';
import {
  FormContainer,
  FormTitle,
  TextField,
  Form,
  FormButton,
} from './components';
import { CompanyMethodSchema } from './validation';

type Values = { company: string };
const containerVariant = {
  initial: {
    opacity: 0,
    transition: { type: 'timing' },
    duration: 500,
  },
  isOpen: { opacity: 1 },
  exit: { opacity: 0, duration: 500 },
};

function TellUsMore() {
  const theme = useTheme();
  const { loadingAuth } = AuthStore;

  const initialValues = useMemo(() => {
    return { company: '' };
  }, []);

  useEffect(
    () => () => {
      AuthStore.reset();
    },
    [],
  );

  const handleSubmit = useCallback(
    async (values: Values, actions: FormikHelpers<Values>) => {
      AuthStore.companyName = values.company;
      AuthModalStore.switchForm('signup');
    },
    [],
  );

  return (
    <FormContainer
      variants={containerVariant}
      style={{ gap: theme.spacing(11) }}
    >
      <Image
        src="/images/login/briefcase.svg"
        height={110}
        width={110}
        objectPosition="center center"
      />
      <Box sx={xw`w-full flex flex-col items-center`}>
        <FormTitle>Tell Us More</FormTitle>
        <Typography
          fontSize={`${theme.spacing(3.5)} !important`}
          lineHeight="1.4 !important"
          variant="body2"
          color="#52545F"
          textAlign="center"
          marginTop={theme.spacing(4)}
        >
          Help us tailor care to your team - enter your company name
        </Typography>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={CompanyMethodSchema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Stack spacing={{ xs: 2, md: 5 }} sx={xw`mb-6 w-full md[mb-8]`}>
            <TextField id="company" name="company" label="Company" />
          </Stack>
          <FormButton isLoading={loadingAuth}>Next</FormButton>
        </Form>
      </Formik>
    </FormContainer>
  );
}

export default observer(TellUsMore);
