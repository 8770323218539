/* eslint-disable global-require */
import React, { useState } from 'react';
import xw from 'xwind';
import styled from '@emotion/styled';
import { InputAdornment, OutlinedTextFieldProps } from '@mui/material';

import TextField from '@/domains/AuthModal/components/TextField';

interface PasswordFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  name: string;
  label?: string;
  placeholder?: string;
}

const SecureIconButton = styled.img`
  ${xw`ml-3 sm:ml-4 mr-4 sm:mr-6`}
  width: 22px;
  height: 22px;

  @media (min-width: 640px) {
    width: 24px;
    height: 24px;
  }
`;

const icons = {
  show: require('../../../../public/icons/eye-show.svg'),
  hide: require('../../../../public/icons/eye-hide.svg'),
};

function PasswordField({
  label,
  placeholder,
  ...props
}: PasswordFieldProps): JSX.Element {
  const [securePassword, setSecurePassword] = useState(true);

  const inputType = securePassword ? 'password' : 'text';
  const iconSrc = securePassword ? icons.hide : icons.show;

  return (
    <>
      <TextField
        {...props}
        type={inputType}
        label={label || placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SecureIconButton
                src={iconSrc}
                onClick={() => setSecurePassword((prev) => !prev)}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

PasswordField.defaultProps = {
  label: '',
  placeholder: '',
};

export default PasswordField;
