import React, { useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import {
  ChildStyledContainer,
  ParentStyledContainer,
} from '@/domains/Village/components/PageContainer/PageContainer.styles';
import PageLayout from '@/widgets/PageLayout/PageLayout';
import { AuthStore } from '@/stores/index';
import useHasMounted from '@/hooks/useHasMounted';
import { useRouter } from 'next/router';
import AsideContent from './AsideContent/AsideContent';
import { Wrapper } from './AsideContent/AsideContent.styles';
import { FiltersOpenedContext } from './FiltersOpenedContext';

type Props = {
  children: React.ReactElement;
};

const CommunityPageLayout = ({ children }: Props) => {
  const [filtersOpened, setFiltersOpened] = useState(false);
  const hasMounted = useHasMounted();
  const router = useRouter();

  const hideAsideForMobile = useMemo(
    () => router.pathname === '/discover/[slug]',
    [router.pathname],
  );

  return (
    <PageLayout>
      <ParentStyledContainer>
        <ChildStyledContainer>
          {!hasMounted ? null : AuthStore.isLoggedIn ? (
            <Grid container spacing={{ xs: 0, md: 11, lg: 12, xl: 15 }}>
              <Grid
                item
                xs={12}
                md={4.5}
                lg={3.5}
                xl={3}
                display={{
                  xs: hideAsideForMobile ? 'none' : 'block',
                  md: 'block',
                }}
              >
                <AsideContent
                  filtersOpened={filtersOpened}
                  setFiltersOpened={setFiltersOpened}
                />
              </Grid>
              <Grid item xs={12} md={7.5} lg={8.5} xl={9}>
                <FiltersOpenedContext.Provider value={filtersOpened}>
                  <Wrapper main>{children}</Wrapper>
                </FiltersOpenedContext.Provider>
              </Grid>
            </Grid>
          ) : (
            <Wrapper main>{children}</Wrapper>
          )}
        </ChildStyledContainer>
      </ParentStyledContainer>
    </PageLayout>
  );
};

export default observer(CommunityPageLayout);
