import { styled } from '@mui/material';

import { Button } from '@/components/Button/Button';

const SocialsButton = styled(Button)`
  min-width: 220px;
  padding: ${({ theme }) => theme.spacing(0, 2, 0, 2)};
  background-color: white;
  letter-spacing: 0 !important;
  color: black;

  .MuiButton-startIcon {
    .icons {
      font-size: 16px;
    }
  }
`;

export default SocialsButton;
