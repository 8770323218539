import xw from 'xwind';
import styled, { StyledComponent } from '@emotion/styled';
import { HTMLAttributes } from 'react';
import { Interpolation, Theme } from '@emotion/react';

const TextInput: StyledComponent<
  HTMLAttributes<HTMLInputElement> & { css: Interpolation<Theme> },
  HTMLAttributes<HTMLInputElement>,
  any
> = styled.input`
  ${xw`
    w-full h-full pl-4 sm:pl-6 pr-4 sm:pr-6 outline-none
    text-sm sm:text-base placeholder-gray-200
  `}
`;

export default TextInput;
