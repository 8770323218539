import xw from 'xwind';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getSkeletonAnimation } from '@/utils/getSkeletonAnimation';

export const buttonStyles = {
  btn: css`
    ${xw`
      flex justify-center items-center
      bg-primary text-base text-white rounded-full tracking-wide
      px-8 outline-none focus:outline-none cursor-pointer
    `}
    height: 42px;
  `,
  outline: xw`border border-primary bg-white text-primary`,
  link: xw`text-base text-primary underline focus:outline-none`,
  btnHover: xw`transition hover[bg-primary text-white]`,
  btnGray: css`
    ${xw('border text-primary text-white')} background-color: #C0C0C0;
    border-color: #c0c0c0;
  `,
  btnGrayOutline: css`
    ${xw`border bg-transparent`}
    border-color: #525255;
    color: #525255;
  `,
  btnRed: xw`border bg-negative`,
};

export const Button = styled.button<{ loading?: boolean }>`
  ${buttonStyles.btn};
  ${({ loading }) => loading && getSkeletonAnimation()}
  ${xw`rounded-full`}
`;

Button.defaultProps = {
  loading: false,
};

export const ButtonOutline = styled(Button)(
  buttonStyles.btn,
  buttonStyles.outline,
);

export const ButtonLink = styled.button(buttonStyles.link);

export const ButtonGray = styled(Button)(
  buttonStyles.btn,
  buttonStyles.btnGray,
);

export const ButtonGrayOutline = styled(Button)(buttonStyles.btnGrayOutline);

export const ButtonNegative = styled(Button)(buttonStyles.btnRed);
