import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { Formik, FormikHelpers } from 'formik';
import { Box, Stack, useTheme } from '@mui/material';
import xw from 'xwind';

import AuthStore from '@/stores/AuthStore';
import AuthModalStore from '@/stores/AuthModalStore';
import InviteStore from '@/stores/InviteStore';

import parseServerError from '@/utils/parseServerError';

import { Button } from '@/components/Button/Button';
import Separator from '@/widgets/Separator';
import { GoogleLogin } from '@/widgets/Google';
import { AppleLogin } from '@/widgets/Apple';
import { getLandingCoachSlugQuery } from '@/utils/common';
import {
  FormContainer,
  FormTitle,
  Agreements,
  TextField,
  PasswordField,
  Form,
  ServerErrors,
  FormButton,
} from './components';
import { initialSignUpValues } from './values';
import { SignUpSchema } from './validation';
import { SignUpValues } from './types';
import GoogleSigninForm from './components/GoogleSigninForm';
import ProviderUserEmail from './components/ProviderUserEmail';

const containerVariant = {
  initial: {
    opacity: 0,
    transition: { type: 'timing' },
    duration: 0.5,
  },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 },
};

function SignUpForm() {
  const theme = useTheme();
  const router = useRouter();
  const { loadingAuth } = AuthStore;
  const { isGoogle, isEmail } = AuthStore.authProviderType;
  const [serverErrors, setServerErrors] = useState(null);

  const { bonus_code: bonus } = router.query || {};

  const patientFirstName = useMemo(() => {
    const { in: name = '' } = router.query ?? {};
    const { firstName } = InviteStore.dataToSignUpByInvite ?? {};

    return firstName || name || '';
  }, [router.query]);

  const initialValues = useMemo(() => {
    const { un = '', ul = '' } = router.query ?? {};
    const [firstName, lastName] = (un as string).split(' ');
    const lastNameFinal = lastName || ul;

    const { isSignUpByInvite, dataToSignUpByInvite } = InviteStore;
    let values = {
      ...initialSignUpValues,
      email: AuthStore.providerEmail,
      ...(firstName && { first_name: firstName }),
      ...(lastNameFinal && { last_name: lastNameFinal }),
    };

    if (isSignUpByInvite) {
      const { villageId, villageSecret } = dataToSignUpByInvite ?? {};
      values = {
        ...values,
        ...(villageId && { village_slug: villageId }),
        ...(villageSecret && { village_secret: villageSecret }),
      };
    }

    return values;
  }, [router.query]);

  const formTitle = patientFirstName
    ? `Join ${patientFirstName}'s Village`
    : 'Сreate Account';

  useEffect(
    () => () => {
      AuthStore.reset();
      InviteStore.reset();
      if (!AuthStore.providerInfoExists) AuthModalStore.switchForm('letsStart');
    },
    [],
  );

  const handleSubmit = useCallback(
    async (values: SignUpValues, actions: FormikHelpers<SignUpValues>) => {
      try {
        AuthStore.loadingAuth = true;
        if (serverErrors) {
          setServerErrors(null);
        }

        const openCoach = getLandingCoachSlugQuery(router)?.coach;

        const signupData: any = { ...values, bonus_code: bonus };
        if (openCoach) {
          signupData.open_coach = openCoach;
        }

        const data = await AuthStore.signup(signupData);

        const { access_token: accessToken, user } = data || {};

        if (accessToken && user) {
          window.dataLayer?.push({
            event: 'signup',
            user: {
              id: user.id,
              first_name: user.first_name,
            },
          });
        } else if (data && !data.user?.is_verified) {
          window.dataLayer?.push({ event: 'signup' });
          AuthModalStore.switchForm('emailSent');
          // AuthStore.storeInactiveUser({
          //   firstName: values.first_name,
          // });
          // router.push(HOME_PATH);
        }
        actions.setSubmitting(false);
      } catch (err) {
        setServerErrors(parseServerError(err?.response?.data));
      } finally {
        AuthStore.loadingAuth = false;
      }
    },
    [bonus, serverErrors, router],
  );

  if (isGoogle) return <GoogleSigninForm />;

  return (
    <FormContainer
      variants={containerVariant}
      style={{ gap: theme.spacing(isEmail ? 3 : 0) }}
    >
      <Box sx={xw`w-full flex flex-col items-center mb-5 sm:mb-8`}>
        <FormTitle>{formTitle}</FormTitle>
        <ServerErrors
          errors={serverErrors || AuthStore.errors}
          css={xw`mt-8`}
        />
      </Box>
      {!isEmail && (
        <>
          <Stack spacing={{ xs: 2, md: 4 }}>
            {/* <FacebookLogin /> */}
            <GoogleLogin />
            <AppleLogin />
          </Stack>
          <Separator label="OR" />
        </>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={SignUpSchema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Stack spacing={{ xs: 2, md: 5 }} sx={xw`mb-3 w-full md[mb-5]`}>
            <ProviderUserEmail />
            <TextField
              id="email"
              name="email"
              label="Email"
              sx={{ display: 'none' }}
            />
            <TextField id="firstName" name="first_name" label="First Name" />
            <TextField id="lastName" name="last_name" label="Last Name" />
            {/* <TextField id="email" name="email" label="Email" /> */}
            <PasswordField id="password" name="password" label="Password" />
          </Stack>
          <FormButton isLoading={loadingAuth}>Сreate Account</FormButton>
        </Form>
      </Formik>

      <Button
        sx={xw`mt-4`}
        variant="text"
        onClick={() => {
          AuthModalStore.switchForm('login');
        }}
      >
        Already have an account?
      </Button>

      <Agreements css={xw`mt-2`} />
    </FormContainer>
  );
}

export default observer(SignUpForm);
