import xw from 'xwind';
import { useField } from 'formik';
import { TextFieldProps } from '@mui/material';

import { capitalize } from '@/utils/index';
import { FloatTextField } from '@/components/Forms/FloatTextField/FloatTextField';
import ErrorMessage from './ErrorMessage';

function TextField({ label, name, ...props }: TextFieldProps): JSX.Element {
  const [field, meta] = useField({ name });
  const shouldShowError = meta.touched && meta.error;
  return (
    <>
      <FloatTextField label={label} {...field} {...props} />
      {shouldShowError && (
        <ErrorMessage css={xw`mt-1 sm:mt-2`}>
          {capitalize(meta.error)}
          {meta.initialError}
        </ErrorMessage>
      )}
    </>
  );
}

TextField.defaultProps = {
  label: '',
};

export default TextField;
