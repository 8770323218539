import { css } from '@emotion/react';
import { styled } from '@mui/material';

import { buttonStyles } from '@/components/buttons';
import { FormContainer } from '../AuthModal/components';

export const Wrapper = styled(FormContainer)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;
  width: auto;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  @media screen and (min-width: 640px) {
    width: auto;
    padding: 4rem 4rem 1.875rem;
  }
  @media screen and (min-width: 768px) {
    padding: 4rem 4rem 1.875rem;
    width: auto;
  }
`;

export const Message = styled('p')`
  width: 260px;
`;

export const StyledLink = styled('a')(
  css([
    buttonStyles.btn,
    css`
      margin-right: 1.5rem;
    `,
  ]),
);
