import { FC, MouseEventHandler } from 'react';
import { observer } from 'mobx-react-lite';

import DiscussionStore from '@/stores/DiscussionStore';
import ModalStore from '@/stores/ModalStore';
import {
  ContentWrapper,
  FeaturedWrapper,
  WarningIcon,
  Message,
  MessageHeader,
  MessageBody,
  ButtonsWrapper,
  Button,
} from '../Modal.styles';

const DiscussionFailureModal: FC = () => {
  const { closeModal } = ModalStore;
  const { clear } = DiscussionStore;

  const onClickHandler: MouseEventHandler = () => {
    clear();
    closeModal();
  };

  return (
    <ContentWrapper>
      <FeaturedWrapper>
        <WarningIcon width={102} height={102} />
      </FeaturedWrapper>
      <Message>
        <MessageHeader>Oops!</MessageHeader>
        <MessageBody>
          Looks like we ran into an issue. Please try again later.
        </MessageBody>
      </Message>
      <ButtonsWrapper>
        <Button onClick={onClickHandler}>Cancel</Button>
      </ButtonsWrapper>
    </ContentWrapper>
  );
};

export default observer(DiscussionFailureModal);
