import { useEffect, memo } from 'react';
import { useRouter } from 'next/router';
import { Url } from 'url';

interface RedirectProps {
  to: string | Partial<Url>;
}

function Redirect({ to }: RedirectProps): JSX.Element {
  const router = useRouter();

  useEffect(() => {
    router.push(to);
  }, [to]);

  return null;
}

export default memo(Redirect);
