import { styled } from '@mui/system';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField/TextField';
import { OutlinedInputProps, TextField, Theme } from '@mui/material';

export const FloatTextField = styled((props: MuiTextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    variant="filled"
    {...props}
  />
))(({ theme, multiline }: { theme: Theme; multiline: boolean }) => ({
  '& .MuiInputLabel-root': {
    fontSize: theme.spacing(4),
    lineHeight: 1,
    color: theme.palette.grey[350],
    top: -2,
    '&.MuiFormLabel-filled, &.Mui-focused': {
      transform: `translate(${theme.spacing(3)}, ${theme.spacing(
        2,
      )}) scale(0.75)`,
    },
  },
  '& .MuiFilledInput-input': {
    fontSize: theme.spacing(4),
    lineHeight: !multiline ? 1.35 : 1.4,
    height: 'auto',
    padding: !multiline ? theme.spacing(4, 3, 0.5) : 0,
    borderRadius: 0,
  },
  '& .MuiFilledInput-root': {
    border: `${theme.spacing(0.5)} solid ${theme.palette.grey[150]}`,
    overflow: 'hidden',
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:before, &:after': {
      content: 'none',
    },
    '&.Mui-focused, &.Mui-hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[100],
    },
  },
})) as typeof TextField;
