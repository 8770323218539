import { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';

import AuthStore from '@/stores/AuthStore';
import { HOME_PATH, WISHLIST_PATH } from '@/common/constants/routes';
import { useRouter } from 'next/router';
import Redirect from './Redirect';

interface AuthGuardProps {
  children: ReactNode;
}

function AuthGuard({ children }: AuthGuardProps): JSX.Element {
  const router = useRouter();
  const { isLoggedIn } = AuthStore;

  if (isLoggedIn) {
    return <>{children}</>;
  }

  if (
    router.pathname === '/vlg/[villageSlug]/[villageTab]' &&
    router.query.villageTab === 'wishlist'
  ) {
    return <Redirect to={`${WISHLIST_PATH}/${router.query.villageSlug}`} />;
  }

  return <Redirect to={HOME_PATH} />;
}

export default observer(AuthGuard);
