import * as Yup from 'yup';

const ERROR_PASSWORD_MIN_MESSAGE = 'Password must be at least 6 characters';
const ERROR_PASSWORD_CONFIRM_MESSAGE = 'Passwords must match';

export const errors = {
  required: 'This field is required.',
  email: 'Invalid email. Please enter your working email address.',
};

const email = Yup.string().email(errors.email).required(errors.required);
const password = Yup.string()
  .min(6, ERROR_PASSWORD_MIN_MESSAGE)
  .required(errors.required);

export const SignUpSchema = Yup.object().shape({
  first_name: Yup.string().required(errors.required),
  last_name: Yup.string().required(errors.required),
  email,
  password,
});

export const AuthMethodSchema = Yup.object().shape({
  email,
});

export const CompanyMethodSchema = Yup.object().shape({
  company: Yup.string().required(errors.required),
});

export const LogInSchema = Yup.object().shape({
  email,
  password,
});

export const ForgotPasswordSchema = Yup.object().shape({
  email,
});

export const ResetPasswordSchema = Yup.object({
  password: Yup.string().required(errors.required),
  passwordConfirmation: Yup.string()
    .required(errors.required)
    .when('password', {
      is: (val: any) => !!(val && val.length > 0),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        ERROR_PASSWORD_CONFIRM_MESSAGE,
      ),
    }),
});
