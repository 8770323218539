import React from 'react';
import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import {
  ChildStyledContainer,
  ParentStyledContainer,
} from '@/domains/Village/components/PageContainer/PageContainer.styles';
import PageLayout from '@/widgets/PageLayout/PageLayout';
import { AuthStore } from '@/stores/index';
import useHasMounted from '@/hooks/useHasMounted';
import AsideContent from './AsideContent/AsideContent';
import { Wrapper } from './AsideContent/AsideContent.styles';

type Props = {
  children: React.ReactElement;
};

const OrgPageLayout = ({ children }: Props) => {
  const hasMounted = useHasMounted();

  return (
    <PageLayout>
      <ParentStyledContainer>
        <ChildStyledContainer>
          {!hasMounted ? null : AuthStore.isLoggedIn ? (
            <Grid container spacing={{ xs: 0, md: 11, lg: 12, xl: 15 }}>
              <Grid item xs={12} md={4.5} lg={3.5} xl={3}>
                <AsideContent />
              </Grid>
              <Grid item xs={12} md={7.5} lg={8.5} xl={9}>
                <Wrapper main>{children}</Wrapper>
              </Grid>
            </Grid>
          ) : (
            <Wrapper main>{children}</Wrapper>
          )}
        </ChildStyledContainer>
      </ParentStyledContainer>
    </PageLayout>
  );
};

export default observer(OrgPageLayout);
