import xw from 'xwind';
import styled from '@emotion/styled';

const FormControl = styled.div(
  xw`
    w-full h-10 overflow-hidden sm:h-12
    border border-transparent focus-within:border-primary rounded-lg
  `,
);

export default FormControl;
