import React, { useCallback, useEffect, useRef, useState } from 'react';
import { NextPage } from 'next';
import getPublicConfig from '@/utils/getPublicConfig';
import { useRouter } from 'next/router';
import App from 'next/app';
import Head from 'next/head';
import { DefaultSeo } from 'next-seo';
import { ApolloProvider } from '@apollo/client';
import { CacheProvider, EmotionCache, Global } from '@emotion/react';
import { Toaster } from 'react-hot-toast';
import * as smoothscroll from 'smoothscroll-polyfill';
import createCache from '@emotion/cache';
import { CssBaseline, ThemeProvider } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';

import AuthModal from '@/domains/AuthModal';
import AuthGuard from '@/widgets/AuthGuard';
import { useShopifyApolloClient, CartProvider } from '@/services/shopify';
import AuthStore from '@/stores/AuthStore';
import { GlobalStyles } from '@/styles';
import { HOME_META_DESC } from '@/common/constants/seo';
import AuthModalStore from '@/stores/AuthModalStore';
import FridgeDoorStore from '@/stores/FridgeDoorStore';
import VillagePageStore from '@/stores/VillagePageStore';
import NotificationsStore from '@/stores/NotificationsStore';
import { QuestionnaireModal } from '@/domains/QuestionnaireModal/QuestionnaireModal';
import getUrlToS3 from '@/utils/get-url-to-s3';
import RootModal from '@/domains/Modals/RootModal';
import GenericModal from '@/widgets/Modal/GenerciModal/GenericModal';
import { useInviteAccept } from '@/hooks/useInviteAccept';
import { FEED_PATH } from '@/common/constants/routes';
import { RegistrationReminder } from '@/domains/blogs/widgets/RegistrationReminder';
import { useInviteOrgAccept } from '@/hooks/useInviteOrgAccept';
import AppPageLayout from '@/components/AppPageLayout/AppPageLayout';
import { theme } from '../../theme.material';

type NextApplicationPage<P = any, IP = P> = NextPage<P, IP> & {
  requireAuth?: boolean;
};

export interface WorkaroundAppProps {
  Component: NextApplicationPage;
  pageProps: any;
  err: any;
  emotionCache?: EmotionCache;
}

if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}

const MyApp = ({
  Component,
  pageProps,
  err,
  emotionCache,
}: WorkaroundAppProps): JSX.Element => {
  const publicRuntimeConfig = getPublicConfig();
  const router = useRouter();
  const initRef = useRef(false);
  const [initFinished, setInitFinished] = useState(false);
  const client = useShopifyApolloClient(pageProps.initialApolloState);
  const { isLoggedIn } = AuthStore;
  const { currentVillageId, villageMap } = FridgeDoorStore;
  const SEO = {
    defaultTitle: 'OneVillage: Personalized Patient and Caregiver Navigation',
    description: HOME_META_DESC,
    openGraph: {
      title: 'OneVillage',
      description: HOME_META_DESC,
      site_name: 'OneVillage',
      type: 'website',
      locale: 'en_US',
      url: `${publicRuntimeConfig.LOCATION_ORIGIN}`,
      images: [
        {
          url: getUrlToS3('/landing/onevillage-social.png'),
          width: 681,
          height: 454,
        },
      ],
    },
    twitter: {
      cardType: 'summary_large_image',
    },
  };

  const handleAcceptInvite = useInviteAccept(
    currentVillageId ? `/u/${currentVillageId}` : FEED_PATH,
  );

  const handleAcceptOrgInvite = useInviteOrgAccept();

  useEffect(() => {
    if (router.query?.in && isLoggedIn) {
      handleAcceptInvite();
    }
    if (router.query?.org && isLoggedIn) {
      handleAcceptOrgInvite();
    }
  }, [
    handleAcceptInvite,
    handleAcceptOrgInvite,
    isLoggedIn,
    router.query?.in,
    router.query?.org,
  ]);

  useEffect(() => {
    const { resetToken, auth } = router?.query ?? {};
    if ((resetToken && auth) || (!auth && !resetToken)) return;

    if (auth) {
      AuthModalStore.showModal(auth as any);
    }

    if (resetToken) {
      AuthModalStore.showModal('resetPassword');
    }
  }, [router?.query]);

  useEffect(() => {
    function setCurrentVillageId(villageId: string) {
      if (villageId && FridgeDoorStore.currentVillageId !== villageId) {
        FridgeDoorStore.currentVillageId = villageId;
        VillagePageStore.fetchVillage(villageId);
      }
    }

    async function fetchDefaultVillage() {
      try {
        const { pathname, query } = router ?? {};
        if (
          [
            '/u/[villageId]',
            '/vlg/[villageSlug]',
            '/vlg/[villageSlug]/[villageTab]',
          ].includes(pathname)
        ) {
          if (
            query?.villageTab === 'wishlist' &&
            !villageMap.has(
              typeof query?.villageSlug === 'string'
                ? query?.villageSlug
                : query?.villageSlug[0],
            )
          ) {
            return;
          }
          setCurrentVillageId(
            (query?.villageId ?? query?.slug ?? query?.villageSlug) as string,
          );
          return;
        }
        const { slug } = (await FridgeDoorStore.fetchDefaultVillage()) ?? {};
        setCurrentVillageId(slug);
      } catch (error) {
        //
      }
    }

    async function init() {
      const accessToken = localStorage?.getItem('access_token');
      if (accessToken) {
        await AuthStore.fetchUser();
        await Promise.all([
          FridgeDoorStore.fetchAllVillages(),
          fetchDefaultVillage(),
          NotificationsStore.fetchUnreadCount(),
        ]);
        setInitFinished(true);
      }

      if (!accessToken && AuthStore.user) {
        AuthStore.logout();
      }
    }

    if (!initRef.current) {
      initRef.current = true;
      init();
    }
  }, [router, villageMap]);

  const handleRouteChange = useCallback(() => {
    const { query } = router || {};

    if (isLoggedIn && query?.notification_id) {
      NotificationsStore.readNotification(Number(query.notification_id));
    }
  }, [router, isLoggedIn]);

  useEffect(() => {
    if (router?.isReady) {
      handleRouteChange();
    }
  }, [router?.isReady, handleRouteChange]);

  useEffect(() => {
    router?.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router?.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, handleRouteChange]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta name="description" content={HOME_META_DESC} />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
        <Global styles={GlobalStyles} />
        <title>{SEO.defaultTitle}</title>
      </Head>
      <DefaultSeo {...SEO} />
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ApolloProvider client={client}>
              <GoogleOAuthProvider
                clientId={publicRuntimeConfig.GOOGLE_CLIENT_ID}
              >
                <CartProvider>
                  <AppPageLayout>
                    {Component.requireAuth ? (
                      <AuthGuard>
                        <Component {...pageProps} err={err} />
                      </AuthGuard>
                    ) : (
                      <Component {...pageProps} err={err} />
                    )}
                  </AppPageLayout>
                  <AuthModal />
                  <QuestionnaireModal />
                  <RootModal />
                  <CssBaseline />
                  <GenericModal />
                  <Toaster position="bottom-left" reverseOrder={false} />
                  <div id="ModalPortal" />
                  {initFinished && <RegistrationReminder />}
                </CartProvider>
              </GoogleOAuthProvider>
            </ApolloProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
};

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

MyApp.defaultProps = {
  emotionCache: createCache({ key: 'css', prepend: true }),
};

export default MyApp;
