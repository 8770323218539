import { SVGAttributes } from 'react';

export function TagIcon(props: SVGAttributes<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6086_139193)">
        <path
          d="M15.3428 3.41628L9.3645 0.0945309C9.25302 0.0325358 9.12756 0 9 0C8.87244 0 8.74698 0.0325358 8.6355 0.0945309L2.65725 3.41628C2.30655 3.61112 2.01433 3.89619 1.81086 4.24195C1.60738 4.58772 1.50006 4.98159 1.5 5.38278V14.25C1.50119 15.2442 1.89666 16.1974 2.59967 16.9004C3.30267 17.6034 4.2558 17.9988 5.25 18H12.75C13.7442 17.9988 14.6973 17.6034 15.4003 16.9004C16.1033 16.1974 16.4988 15.2442 16.5 14.25V5.38278C16.4999 4.98159 16.3926 4.58772 16.1891 4.24195C15.9857 3.89619 15.6934 3.61112 15.3428 3.41628ZM15 14.25C15 14.8468 14.7629 15.4191 14.341 15.841C13.919 16.263 13.3467 16.5 12.75 16.5H5.25C4.65326 16.5 4.08097 16.263 3.65901 15.841C3.23705 15.4191 3 14.8468 3 14.25V5.38278C2.99967 5.24862 3.03533 5.11683 3.10326 5.00114C3.1712 4.88545 3.26892 4.7901 3.38625 4.72503L9 1.60803L14.6145 4.72503C14.7317 4.7902 14.8293 4.88559 14.8971 5.00127C14.9649 5.11695 15.0004 5.2487 15 5.38278V14.25Z"
          fill="currentColor"
        />
        <path
          d="M9 6.37524C9.62132 6.37524 10.125 5.87156 10.125 5.25024C10.125 4.62892 9.62132 4.12524 9 4.12524C8.37868 4.12524 7.875 4.62892 7.875 5.25024C7.875 5.87156 8.37868 6.37524 9 6.37524Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_6086_139193">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
