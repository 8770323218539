import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Formik, FormikHelpers } from 'formik';
import * as Sentry from '@sentry/nextjs';
import xw from 'xwind';

import AuthModalStore from '@/stores/AuthModalStore';
import { api } from '@/services/api';
import parseServerError from '@/utils/parseServerError';
import { AuthStore } from '@/stores/index';
import {
  FormContainer,
  FormTitle,
  FormSubtitle,
  TextField,
  Form,
  ServerErrors,
  FormButton,
  InvalidResetTokenAlert,
} from './components';
import { FormGroup } from './components/Inputs';
import { ForgotPasswordSchema } from './validation';
import { ForgotPasswordValues } from './types';

const containerVariant = {
  initial: {
    opacity: 0,
    transition: { type: 'timing' },
    duration: 500,
  },
  isOpen: { opacity: 1 },
  exit: { opacity: 0, duration: 500 },
};

function ForgotPasswordForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [showInvalidResetTokenAlert, setShowInvalidResetTokenAlert] = useState(
    AuthModalStore.isInvalidResetToken,
  );
  const [serverErrors, setServerErrors] = useState(null);

  useEffect(
    () => () => {
      AuthModalStore.isInvalidResetToken = false;
    },
    [],
  );

  const closeInvalidResetTokenAlert = () => {
    setShowInvalidResetTokenAlert(false);
  };

  const handleBack = () => {
    AuthModalStore.switchForm('login');
  };

  const handleSubmit = useCallback(
    async (
      values: ForgotPasswordValues,
      actions: FormikHelpers<ForgotPasswordValues>,
    ) => {
      try {
        setIsLoading(true);
        if (serverErrors) {
          setServerErrors(null);
        }

        await api.post('/user/password/reset/', values);
        actions.setSubmitting(false);
        AuthModalStore.switchForm('forgotPasswordNotice');
      } catch (err) {
        setServerErrors(parseServerError(err?.response?.data));
        Sentry.captureException(err);
      }
      setIsLoading(false);
    },
    [serverErrors],
  );

  const initialValues = useMemo(
    () => ({ email: AuthStore.providerEmail || '' }),
    [],
  );

  return (
    <FormContainer variants={containerVariant} css={xw`pb-16 sm[pb-12]`}>
      <div css={xw`w-full flex flex-col items-center mb-6`}>
        <FormTitle css={xw`text-center`}>Reset Password</FormTitle>
        <FormSubtitle css={xw`text-center text-gray-350 font-medium mt-8`}>
          Enter your e-mail address and we'll send
          <br css={xw`hidden sm:block`} />
          you a link to change your password.
        </FormSubtitle>

        {showInvalidResetTokenAlert && (
          <div css={xw`w-full mt-8`}>
            <InvalidResetTokenAlert close={closeInvalidResetTokenAlert} />
          </div>
        )}
        <ServerErrors errors={serverErrors} css={xw`mt-6 sm:mt-8`} />
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={ForgotPasswordSchema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormGroup css={xw`mb-0`}>
            <TextField
              id="email"
              name="email"
              placeholder="Enter your e-mail"
              label="Email"
            />
          </FormGroup>
          <FormButton isLoading={isLoading} css={xw`mt-6`}>
            Submit
          </FormButton>
        </Form>
      </Formik>
    </FormContainer>
  );
}

export default observer(ForgotPasswordForm);
