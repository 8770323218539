import { useRef, useState } from 'react';
import { captureException } from '@sentry/nextjs';
import toast from 'react-hot-toast';
import xw from 'xwind';
import orgService from '@/services/orgService';
import { OrgFileType } from '@/types/Content/Org';
import { OrgStore } from '../stores';

export const useUploadOrgFile = ({
  fileKey,
  fileName,
  useObjFileName = false,
}: {
  fileKey: OrgFileType;
  fileName?: string;
  useObjFileName?: boolean;
}) => {
  const fileInputRef = useRef<any>();
  const [loading, setLoading] = useState(false);

  const uploadFile = async (fileObj: File) => {
    if (!fileKey)
      throw Error('Something went wrong, please try again or write to support');

    setLoading(true);
    try {
      const startRes = await OrgStore.startUpload({
        file_name:
          fileName ||
          (useObjFileName && fileObj.name?.replace(' ', '_')) ||
          fileKey ||
          fileObj.name,
        file_type: fileObj.type,
      });
      if (!startRes?.url || !startRes?.file_id || !startRes?.fields)
        throw Error('Something went wrong, invalid server data');

      const { file_id: fileId, url } = startRes;

      await orgService.updloadToCloudStorage(url, fileObj);

      await OrgStore.finishUpload(fileId, fileKey);
    } catch (error) {
      captureException(error);
      toast.error(error.message, {
        duration: 3000,
        style: xw`px-4 text-active`,
      });
    } finally {
      setLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return { loading, setLoading, uploadFile, fileInputRef };
};
