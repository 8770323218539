import xw from 'xwind';

interface ClickableDivProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'css'> {
  disabled?: boolean;
}

function ClickableDiv({
  disabled,
  children,
  ...props
}: ClickableDivProps): JSX.Element {
  function onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (disabled) {
      return;
    }

    props.onClick(event);
  }

  function onKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {
    const enterOrSpace =
      event.key === 'Enter' ||
      event.key === ' ' ||
      event.key === 'Spacebar' ||
      event.which === 13 ||
      event.which === 32;
    if (enterOrSpace) {
      event.preventDefault();
      onClick(event as any);
    }
  }

  return (
    <div
      role="button"
      tabIndex={disabled ? -1 : 0}
      onKeyPress={onKeyPress}
      css={xw`focus:outline-none`}
      {...props}
    >
      {children}
    </div>
  );
}

ClickableDiv.defaultProps = {
  disabled: false,
};

export default ClickableDiv;
