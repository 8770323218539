import { styled } from '@mui/material';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(7)};
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};
`;

export const Section = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3.5)};
`;

export const LinkItem = styled('a')<{ active?: boolean }>`
  display: flex;
  color: ${({ theme, active }) =>
    active ? theme.palette.primary.main : theme.palette.grey[350]};
  gap: ${({ theme }) => theme.spacing(3)};
  align-items: center;
  font-size: ${({ theme }) => theme.spacing(4.75)};
`;

export const LinkIcon = styled('span')`
  flex-basis: ${({ theme }) => theme.spacing(4.5)};
`;

export const LinkContent = styled('span')<{ active?: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.palette.primary.dark : theme.palette.grey[500]};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const DiscoverCTA = styled('div')`
  text-decoration: underline;
  width: unset;
  padding: ${({ theme }) => theme.spacing(2)} 0;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  font-size: ${({ theme }) => theme.spacing(4.5)};
`;
