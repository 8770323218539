import { FC } from 'react';

import {
  ContentWrapper,
  FeaturedWrapper,
  Message,
  MessageBody,
  LoadingIcon,
} from '../Modal.styles';

const DiscussionLoadingModal: FC = () => {
  return (
    <ContentWrapper>
      <FeaturedWrapper>
        <LoadingIcon width={134} height={134} />
      </FeaturedWrapper>
      <Message>
        <MessageBody>
          We're working on erasing your content from the web!
        </MessageBody>
      </Message>
    </ContentWrapper>
  );
};

export default DiscussionLoadingModal;
