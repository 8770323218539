export const HOME_META_DESC =
  'Support the health and well-being of your employees with 1:1 patient and caregiver navigation. Drive greater engagement and performance in your workforce at every level.';

export const TOOLS_META_DESC =
  'Download our free tools and checklists to keep things feeling a little more in control. From Symptom trackers to care schedules, OneVillage has you covered';

export const ADVICE_AND_SUPPORT_META_DESC =
  'Hear the latest from our experts on how to make the process easier for you from end to end.';

export const BRANDING_QUOTE = 'A New Way to Navigate Cancer';
