/* eslint-disable prefer-destructuring */
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import xw from 'xwind';
import AuthModalStore from '@/stores/AuthModalStore';
import AuthStore from '@/stores/AuthStore';
import goToHowItWorks from '@/utils/goToHowItWorks';
import { HOME_PATH, PROFILE_BUILDER } from '@/common/constants/routes';
import Link from 'next/link';
import { Button } from '@/components/Button/Button';
import FridgeDoorStore from '@/stores/FridgeDoorStore';
import { useState } from 'react';
import { IconButton } from '@mui/material';
import Icon from '@/components/icons/Icon';
import { PBStepNames } from '@/domains/ProfileBuilder/constants/PBStepName';
import {
  RegistrationReminderWraper,
  Text,
  ButtonWrapper,
  ThumbsUp,
} from './RegistrationReminder.styles';

function UnloggedMessage(): JSX.Element {
  const router = useRouter();
  const handleHowItWorks = () => goToHowItWorks(router);

  const handleGetEarlyAccess = () => AuthModalStore.showModal('signup');
  return (
    <>
      <div>
        <Text>
          Sign-up to <span style={xw`text-active`}>personalize</span> your
          cancer journey – free, always.
        </Text>
      </div>
      <ButtonWrapper>
        <Button onClick={handleGetEarlyAccess} color="primary">
          Join now
        </Button>
        <Button onClick={handleHowItWorks} variant="outlined">
          How it works
        </Button>
      </ButtonWrapper>
    </>
  );
}

function CompleteRegistryMessage({
  hasVillage,
}: {
  hasVillage: boolean;
}): JSX.Element {
  return (
    <>
      <Text>
        <ThumbsUp>&#x1F929;</ThumbsUp>
        Complete our simple questionnaire to enjoy personalized recommendations
      </Text>
      <ButtonWrapper>
        <Link
          href={
            !hasVillage
              ? `${PROFILE_BUILDER}/${PBStepNames.USER_ROLE}`
              : `${PROFILE_BUILDER}/${PBStepNames.SUBSCRIBE}`
          }
          passHref
        >
          <Button color="secondary">Continue</Button>
        </Link>
      </ButtonWrapper>
    </>
  );
}

const hiddenPageList = [PROFILE_BUILDER];

const RegistrationReminder = (): JSX.Element => {
  const router = useRouter();
  const [visible, setVisible] = useState(true);
  const { isLoggedIn, user, isOrganizationMember } = AuthStore;
  const hasVillage = !FridgeDoorStore.isMustCreateVillage;
  const isHiddenPage =
    hiddenPageList.some((path) => router.pathname.startsWith(path)) ||
    router.pathname === HOME_PATH;

  const isRegistered = (isLoggedIn && hasVillage) || isOrganizationMember;

  return (
    !isHiddenPage &&
    !isRegistered &&
    visible && (
      <div style={xw`sticky bottom-0 z-50`}>
        <RegistrationReminderWraper id="registration-reminder">
          {!isLoggedIn ? null : (
            // <UnloggedMessage />
            <CompleteRegistryMessage hasVillage={hasVillage} />
          )}
        </RegistrationReminderWraper>
        <IconButton
          onClick={() => setVisible(false)}
          css={xw`top-0 right-0 absolute text-xl`}
        >
          <Icon iconName="times" />
        </IconButton>
      </div>
    )
  );
};

export const RegistrationReminderValidator = observer(RegistrationReminder);

export default observer(RegistrationReminder);
