import { SVGAttributes } from 'react';

export function SmallPlayIcon(
  props: SVGAttributes<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.3705 5.97566L8.2155 0.725655C7.65667 0.316444 6.99542 0.0700005 6.30507 0.0136522C5.61473 -0.042696 4.92227 0.0932523 4.30447 0.406423C3.68667 0.719593 3.16768 1.19775 2.80504 1.78787C2.4424 2.37799 2.25029 3.05702 2.25 3.74966V14.2497C2.25012 14.9426 2.44225 15.622 2.80506 16.2124C3.16786 16.8027 3.68716 17.2811 4.30532 17.5942C4.92347 17.9074 5.61631 18.0431 6.30693 17.9864C6.99756 17.9297 7.65897 17.6827 8.21775 17.2729L15.3727 12.0229C15.8477 11.6746 16.2339 11.2193 16.5002 10.6939C16.7664 10.1686 16.9051 9.58788 16.9051 8.99891C16.9051 8.40993 16.7664 7.82923 16.5002 7.30386C16.2339 6.77849 15.8477 6.32321 15.3727 5.97491L15.3705 5.97566ZM14.4825 10.8132L7.3275 16.0632C6.99226 16.3081 6.59578 16.4556 6.18193 16.4891C5.76808 16.5227 5.35302 16.4411 4.98268 16.2534C4.61233 16.0656 4.30116 15.7791 4.0836 15.4255C3.86604 15.0718 3.75058 14.6649 3.75 14.2497V3.74966C3.74584 3.33367 3.85912 2.92494 4.07683 2.57045C4.29454 2.21595 4.60785 1.93007 4.98075 1.74565C5.29792 1.58432 5.64866 1.50004 6.0045 1.49965C6.48142 1.50148 6.94516 1.65632 7.3275 1.9414L14.4825 7.1914C14.7671 7.40043 14.9985 7.67351 15.1581 7.98856C15.3176 8.30361 15.4007 8.65178 15.4007 9.0049C15.4007 9.35803 15.3176 9.7062 15.1581 10.0213C14.9985 10.3363 14.7671 10.6094 14.4825 10.8184V10.8132Z"
        fill="currentColor"
      />
    </svg>
  );
}
