import { SubscriptionGroup } from '@/types/Content/User';
import Link from 'next/link';
import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  LinkItem,
  LinkIcon,
  LinkContent,
} from './SubscribedGroupsMagnet.styles';
import { HashIcon } from '../icons/HashIcon';

type Props = {
  group: SubscriptionGroup;
};

const GroupItem = ({ group }: Props) => {
  const router = useRouter();

  const path = `/discover/${group.slug}`;
  const active = useMemo(() => router.asPath.startsWith(path), [
    path,
    router.asPath,
  ]);

  return (
    <Link href={path} passHref>
      <LinkItem active={active}>
        <LinkIcon>
          <HashIcon />
        </LinkIcon>
        <LinkContent active={active}>{group.name}</LinkContent>
      </LinkItem>
    </Link>
  );
};

export default GroupItem;
