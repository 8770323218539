import { Container } from '@/components/layout';
import { styled } from '@mui/material';

export const ParentStyledContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    max-width: unset;
    margin: 0;
    padding: 0;
  }
`;

export const ChildStyledContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: unset;
    margin: 0;
    padding: 0;
  }
`;
