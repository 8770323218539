import React from 'react';
import { observer } from 'mobx-react-lite';
import xw from 'xwind';

import { DEFAULT_ERROR_MESSAGE } from '@/common/constants/error-messages';
import AuthStore from '@/stores/AuthStore';
import ErrorMessage from './ErrorMessage';

interface ServerErrorsProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'css'> {
  errors?: any;
}

// @TODO: work with shopify errors

function ServerErrors({ errors, ...props }: ServerErrorsProps) {
  const serverErrors = errors || AuthStore.errors;

  if (!serverErrors) return null;

  const renderError = (error: any) => {
    if (Array.isArray(error)) {
      return error.map(renderError);
    }
    if (!error) return DEFAULT_ERROR_MESSAGE;

    const err = error?.msg || error;

    const errorMessage = typeof err === 'string' ? err : JSON.stringify(err);

    return <ErrorMessage key={errorMessage}>{errorMessage}</ErrorMessage>;
  };

  return (
    <div css={xw`text-center`} {...props}>
      {serverErrors.map(renderError)}
    </div>
  );
}

ServerErrors.defaultProps = {
  errors: null,
};

export default observer(ServerErrors);
