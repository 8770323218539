import { Box, Grid, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { ForwardedRef, forwardRef, useState } from 'react';
import { Button } from '../Button/Button';
import {
  ImageUploadInput,
  ImageUploadLabel,
  PreviewImage,
  Wrapper,
} from './UploadImage.styles';
import { UploadImagesPropTypes } from './UploadImage.types';

export const UploadImage = forwardRef(
  (
    {
      label,
      id,
      onAdd,
      onChange,
      value,
      disabled,
      onCancel,
      resetOnSave,
      elevation,
      processing,
    }: UploadImagesPropTypes,
    ref: ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    const [imageSrc, setImageSrc] = useState(value?.url ?? null);
    const [imageFile, setFile] = useState<File>(value?.file ?? null);

    function handleChange(event) {
      const file = event.target.files?.[0];
      if (!file) return;
      const url = URL.createObjectURL(file);
      setImageSrc(url);
      setFile(file);
      if (onChange) {
        onChange({ url, file });
      }
    }

    function handleAdd() {
      onAdd?.({ url: imageSrc, file: imageFile });
      if (resetOnSave) {
        setFile(null);
        setImageSrc(null);
      }
    }
    return (
      <Wrapper elevation={elevation} ref={ref}>
        <Grid flexDirection="column" container gap={5}>
          <ImageUploadLabel htmlFor={id} tabIndex={0}>
            <Stack alignItems="center" sx={{ opacity: imageSrc ? 0 : 1 }}>
              <Typography variant="body2" color="primary">
                {label}
              </Typography>
              <Image src="/icons/image.svg" height="50" width="50" />
            </Stack>
            <PreviewImage>
              {imageSrc && (
                <Box
                  margin="auto"
                  width="100%"
                  height="100%"
                  maxHeight="100%"
                  position="relative"
                >
                  <Image
                    src={imageSrc}
                    loader={({ src }) => src}
                    layout="fill"
                    objectFit="contain"
                    objectPosition="center center"
                  />
                </Box>
              )}
            </PreviewImage>
            <ImageUploadInput
              id={id}
              type="file"
              accept="image/JPG, image/jpg, image/JPEG, image/jpeg,  image/SVG, image/svg, image/PNG, image/png image/GIF, image/gif, image/TIFF image/tiff, image/webp"
              disabled={disabled}
              onChange={handleChange}
              tabIndex={-1}
            />
          </ImageUploadLabel>
          <Grid container justifyItems="stretch" gap={5} item>
            <Grid item flexGrow={1}>
              <Button
                onClick={handleAdd}
                disabled={disabled || !imageSrc}
                fullWidth
                loading={processing}
              >
                Add
              </Button>
            </Grid>
            <Grid item flexGrow={1}>
              <Button
                onClick={onCancel}
                disabled={disabled}
                variant="outlined"
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    );
  },
);

UploadImage.defaultProps = {
  elevation: 0,
  onChange: null,
  value: null,
  resetOnSave: null,
};
