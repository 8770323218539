import { observer } from 'mobx-react-lite';

import AuthModalStore, { AuthModalType } from '@/stores/AuthModalStore';
import FormModal from '@/widgets/Modal/FormModal';
import { useEffect, useRef } from 'react';
import { AuthStore } from '@/stores/index';
import Icon from '@/components/icons/Icon';
import { BackButton } from '@/widgets/Modal/Modal.styles';
import AuthFormFactory from './AuthFormFactory';

const hideBackIconForTypes: Partial<Record<AuthModalType, true>> = {
  letsStart: true,
  forgotPasswordNotice: true,
  emailSent: true,
};
function AuthModal(props): JSX.Element {
  const component = AuthFormFactory(AuthModalStore.type);
  const { isOpen } = AuthModalStore;

  const timeoutRef = useRef<any>(null);
  const prevIsOpen = useRef<boolean>(false);
  useEffect(() => {
    if (!isOpen && prevIsOpen.current) {
      prevIsOpen.current = isOpen;
      timeoutRef.current = setTimeout(() => AuthStore.resetProvider(), 300);
      return () => clearTimeout(timeoutRef.current);
    }

    prevIsOpen.current = isOpen;
    return () => null;
  }, [isOpen]);

  return (
    <FormModal
      open={AuthModalStore.isOpen}
      onClose={() => {
        AuthModalStore.closeModal();
      }}
      {...props}
    >
      {AuthModalStore.type && !hideBackIconForTypes[AuthModalStore.type] && (
        <BackButton
          onClick={() => {
            switch (AuthModalStore.type) {
              case 'signup':
              case 'login':
              case 'tellUsMore':
                AuthModalStore.switchForm('letsStart');
                break;
              case 'forgotPassword':
                AuthModalStore.switchForm('login');
                break;
              case 'resetPassword':
                AuthModalStore.switchForm('forgotPassword');
                break;
              default:
                break;
            }
          }}
        >
          <Icon iconName="chevron-arrow-left" />
        </BackButton>
      )}
      {component}
    </FormModal>
  );
}

export default observer(AuthModal);
