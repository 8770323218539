import Link from 'next/link';
import React, { useMemo } from 'react';
import { MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { useRouter } from 'next/router';
import Icon from '@/components/icons/Icon';

import { MenuItemType } from './helpers';

type Props = {
  item: MenuItemType;
  orgMobile?: boolean;
};

const AsideContentLinkItem = ({ item, orgMobile }: Props) => {
  const router = useRouter();
  const theme = useTheme();
  const { path, iconName, label } = item;

  const active = useMemo(() => router.asPath === path, [path, router.asPath]);

  return (
    <Link href={item.path} passHref>
      <MenuItem component="a" sx={{ p: theme.spacing(2.9, 1.5) }}>
        <Stack
          direction={orgMobile ? 'column' : 'row'}
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
        >
          {!!iconName && (
            <Icon
              iconName={iconName}
              style={{
                color: active
                  ? theme.palette.primary.main
                  : theme.palette.grey[300],
                fontSize: theme.spacing(orgMobile ? 7 : 5),
              }}
            />
          )}
          <Typography
            lineHeight={1}
            fontSize={theme.spacing(orgMobile ? 2.5 : 4.75)}
            variant="h4"
            fontWeight={active ? 700 : 400}
            color={
              active ? theme.palette.primary.dark : theme.palette.grey[500]
            }
          >
            {label}
          </Typography>
        </Stack>
      </MenuItem>
    </Link>
  );
};

export default AsideContentLinkItem;
