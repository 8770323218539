import { FC, MouseEventHandler } from 'react';
import { observer } from 'mobx-react-lite';

import DiscussionStore from '@/stores/DiscussionStore';
import ModalStore from '@/stores/ModalStore';
import {
  ContentWrapper,
  FeaturedWrapper,
  DeleteIcon,
  Message,
  MessageHeader,
  MessageBody,
  ButtonsWrapper,
  ButtonGrayOutline,
  ButtonNegative,
} from '../Modal.styles';

const DiscussionDeleteModal: FC = () => {
  const { closeModal } = ModalStore;
  const { confirmDelete } = DiscussionStore;

  const onCancelHandler: MouseEventHandler = () => {
    closeModal();
  };

  const onDeleteHandler: MouseEventHandler = () => {
    confirmDelete();
  };

  return (
    <ContentWrapper>
      <FeaturedWrapper>
        <DeleteIcon width={94} height={100} />
      </FeaturedWrapper>
      <Message>
        <MessageHeader>Wait!</MessageHeader>
        <MessageBody>
          Thinking of deleting? No going back from here!
        </MessageBody>
      </Message>
      <ButtonsWrapper>
        <ButtonGrayOutline onClick={onCancelHandler}>Cancel</ButtonGrayOutline>
        <ButtonNegative onClick={onDeleteHandler}>Delete</ButtonNegative>
      </ButtonsWrapper>
    </ContentWrapper>
  );
};

export default observer(DiscussionDeleteModal);
