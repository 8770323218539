import { Stack, useTheme } from '@mui/material';
import React from 'react';
import Image from 'next/image';
import { observer } from 'mobx-react-lite';
import { OrgStore } from '@/stores/index';
import { UploadImageFab } from '@/components/CoverSection/components/UploadImageFab/UploadImageFab';
import { useUploadOrgFile } from '@/hooks/useUploadOrgFile';

const OrgBanner = () => {
  const theme = useTheme();

  const { loading, uploadFile } = useUploadOrgFile({
    fileKey: 'image',
    useObjFileName: true,
  });

  async function handleImageUpload({ file }: { file: File }) {
    await uploadFile(file);
  }

  return (
    <Stack
      alignItems="flex-end"
      sx={{
        position: 'relative',
        borderRadius: theme.spacing(4),
        height: theme.spacing(25),
        width: '100%',
        overflow: 'hidden',
        backgroundColor: theme.palette.grey[100],
        padding: theme.spacing(2),
        '&>.MuiButtonBase-root': { visibility: 'hidden' },
        '&:hover>.MuiButtonBase-root': { visibility: 'visible' },
      }}
    >
      <Image
        src={OrgStore.org?.image_url ?? '/images/org/org-avatar.png'}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
      />
      <UploadImageFab
        onUploadImage={handleImageUpload}
        processingImage={loading}
      />
    </Stack>
  );
};

export default observer(OrgBanner);
