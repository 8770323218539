import Icon from '@/components/icons/Icon';
import AuthStore from '@/stores/AuthStore';
import { Stack, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';

const ProviderUserEmail = () => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      flexWrap="nowrap"
      spacing={3}
      sx={{
        color: theme.palette.primary.main,
      }}
    >
      <Icon iconName="email" />

      <Typography
        fontSize={`${theme.spacing(4.75)} !important`}
        lineHeight="1.15 !important"
        variant="body2"
        fontWeight="700"
        color="#82848E"
      >
        {AuthStore.providerEmail}
      </Typography>
    </Stack>
  );
};

export default observer(ProviderUserEmail);
