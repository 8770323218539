import { TTabsNavigationItem } from '@/types/Tabs';

export enum ESettingsTabs {
  NOTIFICATIONS = 'notifications',
  RESOURCES = 'resources',
}

export const tabsNavigation = (hasCompany: boolean): TTabsNavigationItem[] => {
  const nav = [
    {
      title: 'My Profile',
      value: ESettingsTabs.NOTIFICATIONS,
    },
  ];
  if (hasCompany) {
    nav.push({
      title: 'Resources',
      value: ESettingsTabs.RESOURCES,
    });
  }
  return nav;
};
