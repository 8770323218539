import React from 'react';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import Link from 'next/link';

import QuestionnaireModalStore from '@/stores/QuestionnareModalStore';

import getUrlToS3 from '@/utils/get-url-to-s3';
import Modal from '@/widgets/Modal';
import { PROFILE_BUILDER } from '@/common/constants/routes';
import { PBStepNames } from '@/domains/ProfileBuilder/constants/PBStepName';
import { Wrapper, Message, StyledLink } from './QuestionnaireModal.styles';

function QuestionnaireModalComponent(): JSX.Element {
  const handleClose = () => QuestionnaireModalStore?.closeModal();

  return (
    <Modal isOpen={QuestionnaireModalStore?.isOpen} handleClose={handleClose}>
      <Wrapper>
        <Image src={getUrlToS3('/modal/plants.png')} height="197" width="209" />
        <Message>
          Complete this simple questionnaire to enjoy personal recommendations!
        </Message>
        <Link href={`${PROFILE_BUILDER}/${PBStepNames.USER_ROLE}`} passHref>
          <StyledLink onClick={handleClose}>Continue</StyledLink>
        </Link>
      </Wrapper>
    </Modal>
  );
}

export const QuestionnaireModal = observer(QuestionnaireModalComponent);
