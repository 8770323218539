import xw from 'xwind';
import styled from '@emotion/styled';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 1rem;
  @media screen and (min-width: 1024px) {
    justify-content: start;
    flex-direction: row;
    margin-right: 5rem;
  }
`;

export const ThumbsUp = styled.span`
  font-size: 2rem;
  margin-right: 1rem;
`;

export const RegistrationReminderWraper = styled.div`
  box-shadow: 0px -8px 20px 4px rgba(0, 0, 0, 0.15);
  ${xw`
    flex-col sm:flex lg:flex-row sm:justify-between
    items-center px-6 py-8
    bg-white
  `}
`;

export const Text = styled.p(
  xw`
    text-base text-center
    md:text-xl mb-4 lg:mb-0
  `,
);
