import { Asset } from '@sanity/types/dist/dts';
import { captureException } from '@sentry/nextjs';
import { cmsProxyApi } from './cmsProxyApi';

export const imageUploadService = {
  async upload(file: File): Promise<Asset> {
    try {
      const fd = new FormData();
      fd.append('file', file);
      const config = {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${file?.size};`,
          'Content-Disposition': `forma-data; name="file"; filename=${file.name};`,
        },
      };
      const { data } = await cmsProxyApi.post('/file-upload', fd, config);
      return data;
    } catch (error) {
      captureException(error);
      return null;
    }
  },
};
