function MessageIcon(): JSX.Element {
  return (
    <svg
      width="276"
      height="191"
      viewBox="0 0 276 191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.028 60.841s21.757 19.191 36.928 22.575c15.172 3.385 37.46-1.625 50.496 3.414 13.036 5.039 34.024 34.923 39.153 36.126 5.13 1.203 39.343-18.475 42.305-25.23 2.962-6.757-74.681-16.283-78.693-14.266-4.012 2.017-3.982 8.683-3.982 8.683s-3.876 43.022-3.716 47.82c0 0 .195 3.331 1.27 5.016 1.26 1.974 2.07 2.812 4.757 3.3 2.686.487 65.98 9.311 69.556 9.739 3.576.429 4.125-.377 5.525-1.399 1.399-1.022 2.616-6.335 2.616-6.335s.103-13.603 1.496-22.066c.844-5.127.889-4.229 2.91-12.916 2.021-8.686 52.328 7.733 95.888-11.357"
        stroke="#5fa3a9"
        strokeWidth={3}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default MessageIcon;
