import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Stack, useTheme } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import xw from 'xwind';

import { Button } from '@/components/Button/Button';
import { GoogleLogin } from '@/widgets/Google';
import { AppleLogin } from '@/widgets/Apple';
import AuthStore from '@/stores/AuthStore';
import AuthModalStore from '@/stores/AuthModalStore';
import parseServerError from '@/utils/parseServerError';
import Separator from '@/widgets/Separator';
import {
  FormContainer,
  FormTitle,
  Form,
  PasswordField,
  ServerErrors,
  FormButton,
  TextField,
} from './components';
import { initialLogInValues } from './values';
import { LogInSchema } from './validation';
import { LogInValues } from './types';
import ProviderUserEmail from './components/ProviderUserEmail';
import GoogleSigninForm from './components/GoogleSigninForm';

const containerVariant = {
  initial: {
    opacity: 0,
    transition: { type: 'timing' },
    duration: 500,
  },
  isOpen: { opacity: 1 },
  exit: { opacity: 0, duration: 500 },
};

function LogInForm() {
  const theme = useTheme();
  const { loadingAuth } = AuthStore;
  const { isGoogle, isEmail } = AuthStore.authProviderType;

  const [serverErrors, setServerErrors] = useState(null);

  const initialValues = useMemo(() => {
    return {
      ...initialLogInValues,
      email: AuthStore.providerEmail,
    };
  }, []);

  useEffect(
    () => () => {
      AuthStore.reset();
      if (!AuthStore.providerInfoExists) AuthModalStore.switchForm('letsStart');
    },
    [],
  );

  const handleSubmit = useCallback(
    async (values: LogInValues, actions: FormikHelpers<LogInValues>) => {
      try {
        AuthStore.loadingAuth = true;
        if (serverErrors) {
          setServerErrors(null);
        }
        const data = await AuthStore.login(values);
        const { user } = data ?? {};
        const { id, first_name: firstName } = user ?? {};

        window.dataLayer?.push({
          event: 'login',
          user: {
            id,
            first_name: firstName,
          },
        });
        actions.setSubmitting(false);
      } catch (err) {
        AuthStore.loadingAuth = false;
        setServerErrors(parseServerError(err?.response?.data));
      }
    },
    [serverErrors],
  );

  if (isGoogle) return <GoogleSigninForm />;

  return (
    <FormContainer
      variants={containerVariant}
      style={{ gap: theme.spacing(isGoogle || isEmail ? 11 : 8) }}
    >
      <Box sx={xw`w-full flex flex-col items-center`}>
        <FormTitle>Sign In</FormTitle>
        <ServerErrors errors={serverErrors} css={xw`mt-8`} />
      </Box>

      {!isEmail && (
        <>
          <Grid container direction="column" alignItems="center" gap={4}>
            {/* {!isGoogle && (
              <Grid item>
                <FacebookLogin />
              </Grid>
            )} */}
            <Grid item>
              <GoogleLogin />
            </Grid>
            {!isGoogle && (
              <Grid item>
                <AppleLogin />
              </Grid>
            )}
          </Grid>
          {!isGoogle && (
            <Separator label="OR" style={{ marginTop: 0, marginBottom: 0 }} />
          )}
        </>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={LogInSchema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Stack spacing={{ xs: 2, md: 5 }} sx={xw`mb-3 w-full md[mb-5]`}>
            <ProviderUserEmail />
            <TextField
              id="email"
              name="email"
              label="Email"
              sx={{ display: 'none' }}
            />
            <div>
              <PasswordField id="password" name="password" label="Password" />
              <Button
                variant="text"
                onClick={() => {
                  AuthModalStore.switchForm('forgotPassword');
                }}
                sx={{ paddingLeft: 0, textDecoration: 'underline' }}
              >
                Forgot Password?
              </Button>
            </div>
          </Stack>
          <FormButton isLoading={loadingAuth}>Sign In</FormButton>
        </Form>
      </Formik>

      <Button
        variant="text"
        onClick={() => {
          AuthModalStore.switchForm('signup');
        }}
      >
        Don’t have an account?
      </Button>
    </FormContainer>
  );
}

export default observer(LogInForm);
