import React from 'react';
import xw from 'xwind';

interface SeparatorProps {
  label: string;
  style?: any;
}

export default function Separator({
  label,
  style,
}: SeparatorProps): JSX.Element {
  return (
    <div
      css={xw`w-full flex flex-row items-center text-tiny my-1 md:my-3`}
      style={style}
    >
      <span css={xw`w-full border h-px`} />
      <span css={xw`p-2 font-pangram font-bold`}>{label}</span>
      <span css={xw` w-full border h-px`} />
    </div>
  );
}
