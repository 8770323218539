import React from 'react';
import Image from 'next/image';
import { Box, Grid, useTheme } from '@mui/material';
import xw from 'xwind';

import { GoogleLogin } from '@/widgets/Google';
import FormContainer from './FormContainer';
import FormTitle from './FormTitle';
import Agreements from './Agreements';

const containerVariant = {
  initial: {
    opacity: 0,
    transition: { type: 'timing' },
    duration: 500,
  },
  isOpen: { opacity: 1 },
  exit: { opacity: 0, duration: 500 },
};

const GoogleSigninForm = () => {
  const theme = useTheme();
  return (
    <FormContainer
      variants={containerVariant}
      style={{ gap: theme.spacing(11) }}
    >
      <Image
        src="/images/login/ov-google-login.svg"
        height={133}
        width={133}
        objectPosition="center center"
      />
      <Box sx={xw`w-full flex flex-col items-center`}>
        <FormTitle>Corporate Sign In</FormTitle>
      </Box>

      <Grid container direction="column" alignItems="center" gap={4}>
        <Grid item>
          <GoogleLogin />
        </Grid>
      </Grid>
      <Agreements css={xw`mt-2`} login />
    </FormContainer>
  );
};

export default GoogleSigninForm;
