import React from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { ORG_PATH } from '@/common/constants/routes';
import AuthStore from '@/stores/AuthStore';
import OrgStore from '@/stores/OrgStore';
import CommunityPageLayout from './CommunityPageLayout/CommunityPageLayout';
import OrgPageLayout from './OrgPageLayout/OrgPageLayout';

type Props = {
  children: React.ReactElement;
};

const pagesLayouts = {
  '/u/[villageId]': CommunityPageLayout,
  '/discover': CommunityPageLayout,
  '/discover/[slug]': CommunityPageLayout,
  [ORG_PATH]: OrgPageLayout,
  [`${ORG_PATH}/[orgTab]`]: OrgPageLayout,
};

const AppPageLayout = ({ children }: Props) => {
  const router = useRouter();
  const { isLoggedIn } = AuthStore;

  React.useEffect(() => {
    if (isLoggedIn) OrgStore.fetchOrg();
    else OrgStore.resetData();
  }, [isLoggedIn]);

  const Wrapper = pagesLayouts[router.pathname];

  return Wrapper ? <Wrapper>{children}</Wrapper> : children;
};

export default observer(AppPageLayout);
