import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import AsideContentLinkItem from '@/domains/Village/components/AsideContent/AsideContentLinkItem';

import UserContactCard from '@/components/UserContactCard/UserContactCard';
import { useInitChat } from '@/hooks/useInitChat';
import OrgStore from '@/stores/OrgStore';
import { getMenuItems } from './helpers';
import { MenuListStyled, Wrapper } from './AsideContent.styles';
import OrgBanner from './OrgBanner';

const AsideContent = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(`${theme.breakpoints.up('md')}`);
  // const { isLoggedIn } = AuthStore;
  const { org } = OrgStore;

  useEffect(() => {
    if (!org?.id) return;
    OrgStore.fetchAccManager();
  }, [org?.id]);

  const menuItems = useMemo(() => getMenuItems(org?.admin_access, isDesktop), [
    org?.admin_access,
    isDesktop,
  ]);

  const initChat = useInitChat({
    message: OrgStore.accManager?.intercom_chat_init_message,
    slug: OrgStore.accManager?.slug,
  });

  return (
    <Wrapper>
      <Stack direction="column" spacing={7}>
        <Box sx={{ position: 'relative' }}>
          <OrgBanner />
        </Box>
        {isDesktop && (
          <MenuListStyled mdDownRow>
            {menuItems.map((item) => (
              <AsideContentLinkItem key={item.path} item={item} />
            ))}
          </MenuListStyled>
        )}

        <UserContactCard
          loadingInfo={OrgStore.accManagerLoading}
          fullName={[
            OrgStore.accManager?.first_name,
            OrgStore.accManager?.last_name,
          ]
            .filter(Boolean)
            .join(' ')}
          userImage={OrgStore.accManager?.image_url}
          title="Account Manager"
          calendlyProps={{ url: OrgStore.accManager?.calendly_link }}
          onChatClick={initChat}
        />
        {/* {!isDesktop && (
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            flexWrap="wrap"
            sx={{
              backgroundColor: theme.palette.grey[100],
              padding: { xs: theme.spacing(5, 0), sm: theme.spacing(5, 8) },
            }}
          >
            {menuItems.map((item) => (
              <AsideContentLinkItem key={item.path} item={item} orgMobile />
            ))}
          </Stack>
        )} */}
      </Stack>
    </Wrapper>
  );
};

export default observer(AsideContent);
