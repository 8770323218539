import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import { Box, Stack } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import xw from 'xwind';

import AuthStore from '@/stores/AuthStore';
import AuthModalStore from '@/stores/AuthModalStore';
import parseServerError from '@/utils/parseServerError';
import {
  FormContainer,
  FormTitle,
  TextField,
  Form,
  ServerErrors,
  FormButton,
} from './components';
import { initialAuthMethodValues } from './values';
import { AuthMethodSchema } from './validation';
import { AuthMethodValues } from './types';

const containerVariant = {
  initial: {
    opacity: 0,
    transition: { type: 'timing' },
    duration: 500,
  },
  isOpen: { opacity: 1 },
  exit: { opacity: 0, duration: 500 },
};

function LetsStartForm() {
  const router = useRouter();
  const { loadingAuth } = AuthStore;
  const [serverErrors, setServerErrors] = useState(null);

  const { ue: queryEmail = '', email: verificationEmail } = router.query ?? {};

  const initialValues = useMemo(() => {
    const email = AuthStore.providerEmail || queryEmail || verificationEmail;
    return {
      ...initialAuthMethodValues,
      ...(email && !email.includes(' ') && { email }),
    };
  }, [queryEmail, verificationEmail]);

  useEffect(
    () => () => {
      AuthStore.reset();
    },
    [],
  );

  const handleSubmit = useCallback(
    async (
      values: AuthMethodValues,
      actions: FormikHelpers<AuthMethodValues>,
    ) => {
      try {
        if (serverErrors) {
          setServerErrors(null);
        }
        await AuthStore.getAuthMethod(values.email);
        AuthModalStore.switchForm(
          verificationEmail
            ? 'login'
            : AuthStore.showMore
            ? 'tellUsMore'
            : queryEmail || AuthModalStore.isSignupClicked
            ? 'signup'
            : 'login',
        );
      } catch (err) {
        setServerErrors(parseServerError(err?.response?.data));
      }
    },
    [queryEmail, verificationEmail, serverErrors],
  );

  return (
    <FormContainer variants={containerVariant}>
      <Box sx={xw`w-full flex flex-col items-center mb-10`}>
        <FormTitle>Let's get started</FormTitle>
        <ServerErrors errors={serverErrors} css={xw`mt-8`} />
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={AuthMethodSchema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Stack spacing={{ xs: 2, md: 5 }} sx={xw`mb-3 w-full md[mb-5]`}>
            <TextField id="email" name="email" label="Email" />
          </Stack>
          <FormButton isLoading={loadingAuth} css={xw`mb-4`}>
            Proceed
          </FormButton>
        </Form>
      </Formik>
    </FormContainer>
  );
}

export default observer(LetsStartForm);
