import { css } from '@emotion/react';
import xw from 'xwind';

export const GlobalStyles = [
  css`
    @font-face {
      font-family: 'Pangram';
      src: local('Pangram Regular'), local('Pangram-Light'),
        url('/fonts/Pangram-Light.woff2') format('woff2'),
        url('/fonts/Pangram-Light.woff') format('woff'),
        url('/fonts/Pangram-Light.ttf') format('truetype');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Pangram';
      src: local('Pangram Regular'), local('Pangram-Regular'),
        url('/fonts/Pangram-Regular.woff2') format('woff2'),
        url('/fonts/Pangram-Regular.woff') format('woff'),
        url('/fonts/Pangram-Regular.ttf') format('truetype');
      font-weight: 400;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Pangram';
      src: local('Pangram Medium'), local('Pangram-Medium'),
        url('/fonts/Pangram-Medium.woff2') format('woff2'),
        url('/fonts/Pangram-Medium.woff') format('woff'),
        url('/fonts/Pangram-Medium.ttf') format('truetype');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Pangram';
      src: local('Pangram Bold'), local('Pangram-Bold'),
        url('/fonts/Pangram-Bold.woff2') format('woff2'),
        url('/fonts/Pangram-Bold.woff') format('woff'),
        url('/fonts/Pangram-Bold.ttf') format('truetype');
      font-weight: 700;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Pangram';
      src: local('Pangram Extra Bold'), local('Pangram-Extra-Bold'),
        url('/fonts/Pangram-ExtraBold.woff2') format('woff2'),
        url('/fonts/Pangram-ExtraBold.woff') format('woff'),
        url('/fonts/Pangram-ExtraBold.ttf') format('truetype');
      font-weight: 800;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Pangram';
      src: local('Pangram Black'), local('Pangram-Black'),
        url('/fonts/Pangram-Black.woff2') format('woff2'),
        url('/fonts/Pangram-Black.woff') format('woff'),
        url('/fonts/Pangram-Black.ttf') format('truetype');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }

    * {
      font-family: Pangram;
    }

    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.15);
      border-radius: 20px;
    }

    html {
      scroll-behavior: smooth;
      scroll-padding-top: 6rem;
    }
    @media (min-width: 1280px) {
      html {
        scroll-padding-top: 8rem;
      }
    }

    @media (max-width: 1023px) {
      .intercom-lightweight-app-launcher,
      .intercom-namespace .intercom-dfosxs {
        bottom: 70px !important;
      }
    }
  `,
  xw`XWIND_BASE XWIND_GLOBAL`,
  css`
    button:focus {
      outline: 0;
    }
    #__next {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
    }
  `,
];
