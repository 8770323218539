import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import xw from 'xwind';
import { AuthModalStore } from '../../../stores';

type AgreementsProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'css'> & {
  login?: boolean;
};

const ButtonLink = styled.button(
  xw`text-primary font-medium font-pangram focus:outline-none`,
);

function Agreements({ login, ...props }: AgreementsProps): JSX.Element {
  const router = useRouter();

  function navigateToLegalPage(path: string) {
    AuthModalStore.closeModal();
    router.push(path);
  }

  return (
    <div css={xw`text-xs sm:text-sm text-gray-350 text-center`} {...props}>
      By clicking Sign {login ? 'In' : 'Up'} you agree to our
      <br />
      <ButtonLink
        onClick={() => {
          navigateToLegalPage('/terms-and-conditions');
        }}
      >
        Terms of use
      </ButtonLink>{' '}
      and{' '}
      <ButtonLink
        onClick={() => {
          navigateToLegalPage('/privacy-policy');
        }}
      >
        Privacy policy.
      </ButtonLink>
    </div>
  );
}

export default Agreements;
