import xw from 'xwind';

interface InvalidResetTokenAlertProps {
  close: () => void;
}

function InvalidResetTokenAlert({
  close,
}: InvalidResetTokenAlertProps): JSX.Element {
  return (
    <div css={xw`w-full`}>
      <div css={xw`w-full h-1 bg-negative`} />
      <div css={xw`relative bg-red-100 text-white text-center py-4 px-8`}>
        Your password reset link appears to be invalid. Please request a new
        link below.
        <button
          type="button"
          css={xw`absolute top-0.5 right-0.5 w-5 h-5 flex justify-center items-center focus:outline-none`}
          onClick={close}
        >
          <svg
            width={10}
            height={10}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="//www.w3.org/2000/svg"
          >
            <path
              d="M1.065 1.066a.714.714 0 000 1.01l5.917 5.918-5.917 5.918a.714.714 0 101.01 1.01l5.918-5.918 5.917 5.918a.714.714 0 101.01-1.01L9.004 7.994l5.918-5.918a.714.714 0 00-1.01-1.01L7.993 6.984 2.075 1.066a.714.714 0 00-1.01 0z"
              fill="#fff"
              stroke="#fff"
              strokeWidth="1.5"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default InvalidResetTokenAlert;
