import { makeAutoObservable } from 'mobx';

class InviteStore {
  private _isSignUpByInvite = false;

  private _dataToSignUpByInvite = null;

  constructor() {
    makeAutoObservable(this);
  }

  get isSignUpByInvite() {
    return this._isSignUpByInvite;
  }

  get dataToSignUpByInvite() {
    return this._dataToSignUpByInvite;
  }

  prepareDataToSignUp(data: any) {
    if (data) {
      this._isSignUpByInvite = true;
      this._dataToSignUpByInvite = data;
    }
  }

  reset() {
    this._isSignUpByInvite = false;
    this._dataToSignUpByInvite = null;
  }
}

export default new InviteStore();
