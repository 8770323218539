import { FEED_PATH, HOME_PATH } from '@/common/constants/routes';
import { MenuItemType } from '@/domains/Village/components/AsideContent/helpers';

export const getMenuItems = ({
  isLoggedIn,
  currentVillageId,
}: {
  isLoggedIn: boolean;
  currentVillageId: string;
}): MenuItemType[] => {
  const result: MenuItemType[] = [];

  if (isLoggedIn)
    result.push({
      iconName: 'feed',
      label: 'Feed',
      path: currentVillageId ? `${FEED_PATH}/${currentVillageId}` : HOME_PATH,
    });

  result.push({
    iconName: 'users',
    label: 'Groups',
    path: '/discover',
  });

  return result;
};
