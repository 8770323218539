import React, { useMemo } from 'react';
import * as Sentry from '@sentry/nextjs';
import { observer } from 'mobx-react-lite';
import AppleSignin from 'react-apple-signin-auth';

import Icon from '@/components/icons/Icon';
import { SocialsButton } from '@/domains/AuthModal/components';
import AuthStore from '@/stores/AuthStore';
import { EOAuthProvider, IAppleAuthResponse } from '@/types/Auth';
import getPublicConfig from '@/utils/getPublicConfig';
import { useRouter } from 'next/router';

const publicRuntimeConfig = getPublicConfig();

const AppleLoginComponent = (): JSX.Element => {
  const router = useRouter();
  const { bonus_code: bonus } = router.query || {};

  const authOptions = useMemo(
    () => ({
      clientId: publicRuntimeConfig.APPLE_CLIENT_ID,
      scope: 'email name',
      redirectURI: window.location.origin,
      state: 'state',
      nonce: 'nonce',
      usePopup: true,
    }),
    [],
  );

  const handleLogin = async (response: IAppleAuthResponse) => {
    // todo: do not send user name as it is potentially not safe
    try {
      const data = await AuthStore.oauth({
        provider: EOAuthProvider.APPLE,
        access_token: response?.authorization?.id_token,
        first_name: response?.user?.name?.firstName,
        last_name: response?.user?.name?.lastName,
        bonus_code: bonus as string,
      });

      const { access_token: accessToken, user } = data || {};
      if (accessToken && user) {
        window.dataLayer?.push({
          event: 'login',
          user: {
            id: user.id,
            first_name: user.first_name,
          },
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <AppleSignin
      authOptions={authOptions}
      onSuccess={handleLogin}
      render={(props) => (
        <SocialsButton
          variant="outlined"
          startIcon={<Icon iconName="apple" />}
          {...props}
        >
          Sign In with Apple
        </SocialsButton>
      )}
    />
  );
};

export const AppleLogin = observer(AppleLoginComponent);
