import { Paper, styled } from '@mui/material';
import { alpha } from '@mui/system';

export const ImageUploadLabel = styled('label')`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing(4)};
  background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.13)};
  border-radius: ${({ theme }) => theme.spacing(4)};
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const ImageUploadInput = styled('input')`
  position: absolute;
  left: -50000px;
`;

export const PreviewImage = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-items: center;
  align-items: center;
`;

export const Wrapper = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(5)};
`;
