import { ModalProps } from '@/widgets/Modal/Modal';
import { makeAutoObservable } from 'mobx';

class GenericModalStore {
  private _modalProps: ModalProps = { isOpen: false, handleClose: null };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  updateModal = (newProps: ModalProps = { isOpen: false }) => {
    this._modalProps = {
      pageProps: {
        headerProps: {
          ...this._modalProps?.pageProps?.headerProps,
          ...newProps?.pageProps?.headerProps,
        },
        paperProps: {
          ...this._modalProps?.pageProps?.paperProps,
          ...newProps?.pageProps?.paperProps,
        },
        ...newProps?.pageProps,
      },
      ...this?._modalProps,
      ...newProps,
    };
  };

  get modalProps(): ModalProps {
    return this._modalProps;
  }

  handleClose = () => {
    this.updateModal({ isOpen: false });
  };
}

const genericModalStore = new GenericModalStore();

export default genericModalStore;
