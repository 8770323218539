import React from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import xw from 'xwind';
import AuthStore from '@/stores/AuthStore';
import Icon from '@/components/icons/Icon';
import { FormTitle } from './components';

const EmailSent = () => {
  const theme = useTheme();

  const renderLink = (title: string, href: string) => (
    <a target="_blank" rel="noreferrer" href={href}>
      <Typography
        fontSize={`${theme.spacing(4)} !important`}
        lineHeight="1.4 !important"
        variant="body2"
        color="#52545F"
        textAlign="center"
        margin={theme.spacing(3, 3)}
      >
        {title}
        <Icon iconName="open-link" style={{ marginLeft: theme.spacing(2) }} />
      </Typography>
    </a>
  );

  return (
    <Box sx={xw`w-full flex flex-col items-center`}>
      <FormTitle>Welcome to OneVillage!</FormTitle>
      <Typography
        fontSize={`${theme.spacing(4)} !important`}
        lineHeight="1.4 !important"
        variant="body2"
        color="#52545F"
        textAlign="center"
        margin={theme.spacing(15, 3, 0)}
      >
        To complete your registration, we've sent a verification link to the
        email address you provided: <b>{AuthStore.providerEmail}</b>. Please
        click on the link in the email to confirm your email address and
        activate your account.
      </Typography>
      <Typography
        fontSize={`${theme.spacing(4)} !important`}
        lineHeight="1.4 !important"
        variant="body2"
        color="#52545F"
        textAlign="center"
        margin={theme.spacing(3, 3, 0)}
      >
        If you haven't received the email, please check your spam folder.
      </Typography>
      <Stack
        sx={{ width: '100%', maxWidth: theme.spacing(100) }}
        margin={theme.spacing(5, 3, 8)}
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        {renderLink('Open Gmail', 'http://gmail.com')}
        {renderLink('Open Outlook', 'http://outlook.com')}
      </Stack>
    </Box>
  );
};

export default EmailSent;
