import GenericModalStore from '@/stores/GenericModalStore';
import { observer } from 'mobx-react-lite';
import Modal from '../Modal';

function GenericModal(): JSX.Element {
  const props = GenericModalStore.modalProps;

  // eslint-disable-next-line prefer-destructuring
  const handleClose = GenericModalStore.handleClose;

  return <Modal {...props} handleClose={handleClose} />;
}

export default observer(GenericModal);
