import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import xw from 'xwind';
import { Formik, FormikHelpers } from 'formik';
import * as Sentry from '@sentry/nextjs';
import { AnimatePresence, motion } from 'framer-motion';

import { HOME_PATH } from '@/common/constants/routes';
import LoadingIndicator from '@/components/LoadingIndicator';
import Icon from '@/components/icons/Icon';
import { BackButton } from '@/widgets/Modal/Modal.styles';
import AuthModalStore from '@/stores/AuthModalStore';
import { api } from '@/services/api';
import parseServerError from '@/utils/parseServerError';
import {
  FormContainer,
  FormTitle,
  Form,
  ServerErrors,
  FormButton,
  PasswordField,
} from './components';
import { FormGroup } from './components/Inputs';
import { initialResetPasswordValues } from './values';
import { ResetPasswordSchema } from './validation';
import { ResetPasswordValues } from './types';

function ResetPasswordForm() {
  const router = useRouter();
  const { resetToken } = router?.query ?? {};

  const [isLoading, setIsLoading] = useState(false);
  const [isValidatingToken, setIsValidatingToken] = useState(true);
  const [serverErrors, setServerErrors] = useState(null);

  useEffect(() => {
    async function validateToken(token: string) {
      try {
        await api.post('/user/password/reset/validate_token/', {
          token,
        });
      } catch (error) {
        AuthModalStore.isInvalidResetToken = true;
        AuthModalStore.switchForm('forgotPassword');
      }
      setIsValidatingToken(false);
    }

    if (resetToken) {
      validateToken(resetToken as string);
    }
  }, [resetToken]);

  const handleSubmit = useCallback(
    async (
      values: ResetPasswordValues,
      actions: FormikHelpers<ResetPasswordValues>,
    ) => {
      try {
        setIsLoading(true);
        if (serverErrors) {
          setServerErrors(null);
        }

        await api.post('/user/password/reset/confirm/', {
          password: values.password,
          token: resetToken,
        });

        actions.setSubmitting(false);
        AuthModalStore.switchForm('login');
        router.replace(HOME_PATH, undefined, { shallow: true });
      } catch (err) {
        setServerErrors(parseServerError(err?.response?.data));
        Sentry.captureException(err);
      }
      setIsLoading(false);
    },
    [resetToken, router, serverErrors],
  );

  return (
    <FormContainer css={xw`relative overflow-hidden pb-16 sm[pb-12]`}>
      <div css={xw`w-full flex flex-col items-center mb-8 sm:mb-8`}>
        <FormTitle css={xw`text-center`}>Reset Password</FormTitle>
        <ServerErrors errors={serverErrors} css={xw`mt-8 sm:mt-10`} />
      </div>

      <Formik
        initialValues={initialResetPasswordValues}
        validationSchema={ResetPasswordSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <FormGroup css={xw`mb-5`}>
            <PasswordField
              id="password"
              name="password"
              placeholder="Enter a password"
              label="Create a new password"
            />
          </FormGroup>
          <FormGroup css={xw`mb-0`}>
            <PasswordField
              id="passwordConfirmation"
              name="passwordConfirmation"
              placeholder="Enter a password one more time"
              label="Repeat your password"
            />
          </FormGroup>
          <FormButton isLoading={isLoading} css={xw`mt-6`}>
            Change Password
          </FormButton>
        </Form>
      </Formik>

      <AnimatePresence>
        {isValidatingToken && (
          <motion.div
            css={xw`absolute inset-0 bg-gray-100 bg-opacity-90 flex justify-center items-center`}
          >
            <span css={xw`text-primary text-lg mr-4`}>Validating...</span>
            <LoadingIndicator />
          </motion.div>
        )}
      </AnimatePresence>
    </FormContainer>
  );
}

export default observer(ResetPasswordForm);
