import { useCallback, useRef } from 'react';
import { useRouter } from 'next/router';
import { VILLAGE_PATH } from '@/common/constants/routes';
import VillagePageStore from '@/stores/VillagePageStore';
import FridgeDoorStore from '@/stores/FridgeDoorStore';
import userService from '@/services/userService';

export const useInviteAccept = (defaultPath: string): (() => void) => {
  const router = useRouter();
  const isInviteConfirmed = useRef(false);
  const { village } = VillagePageStore;

  const { in: inviteName } = router.query;
  const isInviteLink = !!inviteName;

  return useCallback(() => {
    const confirmInvite = async () => {
      const data = await userService.acceptInvite();
      if (data.length) {
        router.replace(`${VILLAGE_PATH}/${data[0].slug}/home`);
        if (village?.slug !== data[0].slug) {
          FridgeDoorStore.currentVillageId = data[0].slug;
          VillagePageStore.fetchVillage(data[0].slug);
        }
      } else {
        router.replace(defaultPath);
      }
    };

    if (isInviteLink && !isInviteConfirmed.current) {
      isInviteConfirmed.current = true;
      confirmInvite();
    }
  }, [defaultPath, isInviteLink, router, village?.slug]);
};
