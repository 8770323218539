import { Parser } from '@/components/TextEditor/Parser.class';
import { ImageMapType } from '@/components/TextEditor/TextEditor.types';
import Discussion from '@/types/Discussions';
import { Reference } from '@sanity/types/dist/dts';
import * as Sentry from '@sentry/nextjs';
import { HTTPListRequestParams } from '@/types/HTTPListRequestParams';
import { cmsProxyApi } from './cmsProxyApi';
import { imageUploadService } from './imageUploadService';

interface ServiceResponse {
  discussion: Discussion;
  error: any;
}

interface CreateParams {
  title: string;
  userId: string;
  body: Array<Parser>;
  groupId: string;
  imageMap: ImageMapType;
}

const parseData = (data): Discussion => {
  return {
    ...data,
    body: data.body?.all ?? data.body,
  };
};

const discussionService = {
  async getDiscussions(params: HTTPListRequestParams): Promise<Discussion[]> {
    try {
      const { data } = await cmsProxyApi.get('/discussions', {
        params,
      });

      return data.results;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  },

  async getBySlug(slug: string): Promise<ServiceResponse> {
    try {
      const { data } = await cmsProxyApi.get<Discussion>(
        `/discussions/${slug}`,
      );
      return { discussion: parseData(data), error: null };
    } catch (e) {
      Sentry.captureException(e);

      return { discussion: null, error: e };
    }
  },

  async create(params: CreateParams): Promise<ServiceResponse> {
    try {
      const promises = [];
      params.body.forEach((block: any) => {
        if (block._type === 'image') {
          const { file } = params.imageMap[block.asset.url];
          const promise = imageUploadService.upload(file).then((asset) => {
            // eslint-disable-next-line no-param-reassign
            block.asset = ({
              _type: 'reference',
              _ref: asset._id,
            } as unknown) as Reference;
          });

          promises.push(promise);
        }
      });

      await Promise.allSettled(promises);

      const { data } = await cmsProxyApi.post<Discussion>('/discussions', {
        ...params,
        body: { _type: 'localeBlockContent', all: params.body },
      });

      return { discussion: data, error: null };
    } catch (e) {
      Sentry.captureException(e);
      return { discussion: null, error: e };
    }
  },

  async delete(id: string): Promise<ServiceResponse> {
    try {
      const { data } = await cmsProxyApi.delete(`/discussions/${id}`);

      return { discussion: data, error: null };
    } catch (e) {
      Sentry.captureException(e);

      return { discussion: null, error: e };
    }
  },
};

export default discussionService;
