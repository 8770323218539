import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AuthStore from '@/stores/AuthStore';
import FridgeDoorStore from '@/stores/FridgeDoorStore';
import AsideContentLinkItem from '@/domains/Village/components/AsideContent/AsideContentLinkItem';
import { SubscribedGroupsMagnet } from '@/components/SubscribedGroupsMagnet/SubscribedGroupsMagnet';
import CustomAvatar from '@/components/CustomAvatar';
import { Button } from '@/components/Button/Button';
import Icon from '@/components/icons/Icon';
import getIntercom from '@/services/intercom';

import { getMenuItems } from './helpers';
import {
  BlockWrapper,
  FilterButton,
  MenuListStyled,
  Wrapper,
} from './AsideContent.styles';

const CHAT_INIT_DONE_LS_KEY = 'intercom-community-chat-init-done';

type Props = {
  filtersOpened: boolean;
  setFiltersOpened: React.Dispatch<React.SetStateAction<boolean>>;
};
const AsideContent = ({ filtersOpened, setFiltersOpened }: Props) => {
  const theme = useTheme();
  const { isLoggedIn } = AuthStore;
  const { currentVillageId } = FridgeDoorStore;
  const isDesktop = useMediaQuery(`${theme.breakpoints.up('md')}`);

  const menuItems = useMemo(
    () => getMenuItems({ isLoggedIn, currentVillageId }),
    [isLoggedIn, currentVillageId],
  );

  const [chatInitDone, setChatInitDone] = useState(() =>
    typeof window !== 'undefined'
      ? !!localStorage?.getItem(CHAT_INIT_DONE_LS_KEY)
      : false,
  );

  return (
    <Wrapper>
      <Stack direction="column" spacing={6}>
        <BlockWrapper pl>
          <MenuListStyled mdDownRow>
            {menuItems.map((item) => (
              <AsideContentLinkItem key={item.path} item={item} />
            ))}
            <div style={{ flex: 1 }} />
            {!isDesktop && (
              <FilterButton
                onClick={() => setFiltersOpened((prev) => !prev)}
                variant={filtersOpened ? 'contained' : 'text'}
                active={filtersOpened}
              >
                <Icon iconName="filter" />
              </FilterButton>
            )}
          </MenuListStyled>
        </BlockWrapper>
        {isDesktop && (
          <>
            <Divider />
            <BlockWrapper>
              <SubscribedGroupsMagnet
                subscribedGroups={AuthStore.cmsUser?.subscriptionGroups}
              />
            </BlockWrapper>
            <Divider />
            <BlockWrapper>
              <Stack spacing={3} direction="row">
                <CustomAvatar
                  size={{ desktop: 13.5, mobile: 13.5 }}
                  fontSize={{ desktop: 7, mobile: 7 }}
                  name="Linda Neff"
                  image="/images/ov-avatar/LindsaySmithAvatar2.png"
                />
                <Stack direction="column" justifyContent="center">
                  <Typography
                    lineHeight={1.3}
                    variant="body2"
                    whiteSpace="pre-line"
                    sx={{ maxWidth: theme.spacing(80) }}
                  >
                    Looking for some guidance on where to get involved in the
                    community?
                  </Typography>
                </Stack>
              </Stack>
              <Button
                sx={{
                  width: '100%',
                  color: theme.palette.warning.contrastText,
                  borderWidth: '2px',
                  marginTop: theme.spacing(4),
                }}
                small
                color="secondary"
                variant="contained"
                onClick={async () => {
                  if (chatInitDone) {
                    getIntercom()?.('show');
                    return;
                  }

                  getIntercom()?.('showNewMessage');

                  localStorage.setItem(CHAT_INIT_DONE_LS_KEY, '1');
                  setChatInitDone(true);
                }}
              >
                Chat Now
              </Button>
            </BlockWrapper>
          </>
        )}
      </Stack>
    </Wrapper>
  );
};

export default observer(AsideContent);
