import { ClickAwayListener, Fab, Fade, Slide } from '@mui/material';
import React, { useState, useRef, KeyboardEvent } from 'react';
import { UploadImage } from '@/components/UploadImage/UploadImage';
import Icon from '@/components/icons/Icon';
import { PopperStyled } from './UploadImageFab.styles';
import { CoverSectionPropTypes } from '../../CoverSection.types';

const FOCUSABLE_ELEMENTS_QUERY =
  'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled]), *[tabindex="0"]';

export function UploadImageFab({
  onUploadImage,
  processingImage,
  className,
}: Pick<
  CoverSectionPropTypes,
  'onUploadImage' | 'processingImage' | 'className'
>): JSX.Element {
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  function togglePopper() {
    setOpen(!open);
  }

  function handleClickAway(event) {
    const childList = Array.from(anchor.childNodes) as Array<HTMLElement>;
    const isTargetWithing =
      childList.includes(event.target) || event.target === anchor;

    if (open && !isTargetWithing) {
      setOpen(false);
    }
  }

  function focusTrap(event: KeyboardEvent<HTMLElement>) {
    if (event.key === 'Escape') {
      setOpen(false);
      anchor.focus();
      return;
    }

    const isTabPressed = event.key === 'Tab' || event.keyCode === 9;

    const focusableElements =
      menuRef.current?.querySelectorAll(FOCUSABLE_ELEMENTS_QUERY) || null;

    if (!isTabPressed || !focusableElements) {
      return;
    }

    event.stopPropagation();

    const firstElement = focusableElements[0] as HTMLElement;
    const lastElement = focusableElements[
      focusableElements.length - 1
    ] as HTMLElement;
    if (event.shiftKey) {
      if (document.activeElement === firstElement) {
        event.preventDefault();
        lastElement.focus();
      }
      return;
    }

    if (document.activeElement === lastElement) {
      firstElement.focus();
      event.preventDefault();
    }
  }
  async function handleUpload(event) {
    await onUploadImage(event);
    setOpen(false);
  }

  return (
    <>
      <Fab
        onClick={togglePopper}
        onKeyDown={focusTrap}
        ref={setAnchor}
        size="small"
        color="primary"
        aria-label="Edit cover photo"
        aria-controls="uploadComps"
        aria-haspopup
        aria-expanded={open}
        className={className}
        sx={(theme) => ({
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.grey[700],
        })}
      >
        <Icon iconName="pencil" />
      </Fab>
      <ClickAwayListener onClickAway={handleClickAway}>
        <PopperStyled
          anchorEl={anchor}
          open={open}
          transition
          onKeyDownCapture={focusTrap}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Slide {...TransitionProps}>
                <UploadImage
                  elevation={5}
                  processing={processingImage}
                  ref={menuRef}
                  onKeyDown={focusTrap}
                  id="imgUpload"
                  disabled={!open}
                  label="Select an Image"
                  onAdd={handleUpload}
                  resetOnSave={true}
                  onCancel={() => setOpen(false)}
                />
              </Slide>
            </Fade>
          )}
        </PopperStyled>
      </ClickAwayListener>
    </>
  );
}
