import xw from 'xwind';
import { FormContainer, FormTitle, MessageIcon } from './components';

const ForgotPasswordNotice = (): JSX.Element => {
  function openLiveChat() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Intercom('show');
  }

  return (
    <FormContainer>
      <div css={xw`w-full flex flex-col items-center`}>
        <MessageIcon />
        <FormTitle css={xw`text-black mt-10`}> Thanks!</FormTitle>
      </div>
      <div css={xw`my-10`}>
        <p css={xw`text-xl text-gray-400 text-center`}>
          If you provided a valid email, we've sent
          <br css={xw`hidden xs:block`} /> you a link to change your password.
        </p>
      </div>
      <div css={xw`mb-28`}>
        <p css={xw`text-xl text-gray-400 text-center`}>
          If you are still unable to log in, please
          <br /> contact{' '}
          <button
            type="button"
            css={xw`text-active focus:outline-none`}
            onClick={openLiveChat}
          >
            customer support
          </button>{' '}
          for assistance.
        </p>
      </div>
    </FormContainer>
  );
};

export default ForgotPasswordNotice;
