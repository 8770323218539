import React, { useMemo } from 'react';
import {
  CalendlyEmbeded,
  CalendlyEmbededProps,
} from '@/components/CalendlyEmbeded';
import Icon from '@/components/icons/Icon';
import CustomAvatar from '@/components/CustomAvatar';
import { Skeleton, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { Button } from '@/components/Button/Button';

export type UserContactCardProps = {
  direction?: 'column' | 'row';
  loadingInfo?: boolean;
  fullName: string;
  userImage?: string;
  title: string;
  calendlyProps?: Partial<CalendlyEmbededProps>;
  onChatClick?: () => void;
  sx?: SxProps;
  buttonsSx?: SxProps;
  buttonSx?: SxProps;
  nameColor?: string;
  nameSize?: string;
  avatarSize?: number;
  infoGap?: number;
  showButtons?: boolean;
  calendlyButtonLabel?: string;
  note?: string;
  noteSx?: SxProps;
};

const UserContactCard = ({
  direction = 'column',
  loadingInfo = false,
  fullName,
  userImage,
  title,
  calendlyProps,
  onChatClick,
  sx,
  buttonsSx,
  buttonSx,
  nameColor,
  nameSize,
  avatarSize = 13.5,
  infoGap = 1,
  showButtons = true,
  calendlyButtonLabel,
  note,
  noteSx,
}: UserContactCardProps) => {
  const theme = useTheme();

  const isRow = useMemo(() => direction === 'row', [direction]);

  const buttonStyle: SxProps = isRow
    ? {
        flex: '1 0 9rem',
        maxWidth: { xs: 'unset', sm: theme.spacing(49.5) },
        ...buttonSx,
      }
    : { width: '100%', ...buttonSx };
  const buttonsWrapper = (child: React.ReactNode) => {
    return !showButtons ? null : isRow ? (
      <Stack
        direction="row"
        flexWrap="wrap"
        sx={{ flex: { xs: '1 0 10rem', sm: '1 0 19rem' }, ...buttonsSx }}
        gap={3}
      >
        {child}
      </Stack>
    ) : (
      <Stack direction="column" gap={3} sx={buttonsSx}>
        {child}
      </Stack>
    );
  };

  return (
    <Stack
      direction={direction}
      flexWrap={isRow ? 'wrap' : undefined}
      alignItems={isRow ? 'center' : undefined}
      gap={3}
      sx={sx}
    >
      <Stack
        gap={3}
        direction="row"
        sx={isRow ? { marginRight: theme.spacing(4) } : undefined}
      >
        {loadingInfo ? (
          <Skeleton
            variant="circular"
            width={theme.spacing(avatarSize)}
            height={theme.spacing(avatarSize)}
          />
        ) : (
          <CustomAvatar
            size={{ desktop: avatarSize, mobile: avatarSize }}
            fontSize={{ desktop: 7, mobile: 7 }}
            name={fullName}
            image={userImage}
          />
        )}
        <Stack
          direction="column"
          justifyContent="center"
          gap={theme.spacing(infoGap)}
        >
          {loadingInfo ? (
            <Skeleton
              variant="rectangular"
              width={theme.spacing(29)}
              height={theme.spacing(4)}
              style={{ borderRadius: theme.spacing(2), maxWidth: '100%' }}
            />
          ) : (
            <Typography
              lineHeight={1}
              variant="h4"
              whiteSpace="pre-line"
              style={{
                color: nameColor || undefined,
                fontSize: nameSize || undefined,
              }}
            >
              {fullName}
            </Typography>
          )}
          {loadingInfo ? (
            <Skeleton
              variant="rectangular"
              width={theme.spacing(40)}
              height={theme.spacing(3)}
              style={{
                borderRadius: theme.spacing(2),
                maxWidth: '100%',
                marginTop: theme.spacing(1),
              }}
            />
          ) : (
            <Typography lineHeight={1} variant="body2" whiteSpace="pre-line">
              {title}
            </Typography>
          )}
        </Stack>
      </Stack>
      {note ? (
        <Typography
          lineHeight="1.4 !important"
          fontSize={theme.spacing(3.5)}
          variant="body2"
          sx={noteSx}
        >
          {note}
        </Typography>
      ) : null}
      {buttonsWrapper(
        <>
          {calendlyProps?.url ? (
            <CalendlyEmbeded
              sx={buttonStyle}
              small
              disabled={loadingInfo}
              {...calendlyProps}
            >
              {calendlyButtonLabel || 'Book a Call'}
            </CalendlyEmbeded>
          ) : null}
          {onChatClick ? (
            <Button
              sx={{
                color: theme.palette.warning.contrastText,
                borderWidth: '2px',
                ...buttonStyle,
              }}
              small
              startIcon={
                <Icon
                  iconName="start-chat"
                  style={{ marginRight: theme.spacing(1) }}
                />
              }
              color="secondary"
              variant="outlined"
              onClick={onChatClick}
              disabled={loadingInfo}
            >
              Chat
            </Button>
          ) : null}
        </>,
      )}
    </Stack>
  );
};

export default UserContactCard;
