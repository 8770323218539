import { ButtonHTMLAttributes } from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import { Interpolation, Theme } from '@emotion/react';
import xw from 'xwind';

import { Button } from '@/components/Button/Button';
import Spinner from '@/components/Spinner';

interface FormButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'css'> {
  isLoading?: boolean;
  css?: Interpolation<Theme>;
}

const StyledButton: StyledComponent<
  ButtonHTMLAttributes<HTMLButtonElement> & { css: Interpolation<Theme> },
  ButtonHTMLAttributes<HTMLButtonElement>,
  any
> = styled(Button)(xw`w-full sm:w-3/4`);

function FormButton({
  isLoading,
  children,
  ...props
}: FormButtonProps): JSX.Element {
  return (
    <StyledButton type="submit" disabled={isLoading} {...props}>
      {isLoading ? <Spinner /> : children}
    </StyledButton>
  );
}

FormButton.defaultProps = {
  isLoading: false,
  css: undefined,
};

export default FormButton;
