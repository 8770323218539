import { Button } from '@/components/Button/Button';
import { styled, MenuList } from '@mui/material';

export const Wrapper = styled('div')<{ main?: boolean }>`
  padding: ${({ theme }) => theme.spacing(11)} 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: ${({ main, theme }) => theme.spacing(7, 0, main ? 7 : 0)};
  }
`;

export const BlockWrapper = styled('div')<{ pl?: boolean }>`
  ${({ theme, pl }) => theme.breakpoints.up(pl ? 'xs' : 'md')} {
    padding-left: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const MenuListStyled = styled(MenuList)<{ mdDownRow?: boolean }>`
  padding: 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    flex-flow: row wrap;
    gap: ${({ theme }) => theme.spacing(3, 7)};
    justify-content: flex-start;
    align-items: center;
  }
`;
