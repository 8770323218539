export const initialSignUpValues = {
  email: '',
  first_name: '',
  last_name: '',
  password: '',
};

export const initialAuthMethodValues = {
  email: '',
};

export const initialLogInValues = {
  email: '',
  password: '',
};

export const initialForgotPasswordValues = {
  email: '',
};

export const initialResetPasswordValues = {
  password: '',
  passwordConfirmation: '',
};
