import { makeAutoObservable, runInAction } from 'mobx';
import toast from 'react-hot-toast';
import { css } from '@emotion/css';

import { ModalTypes } from '@/domains/Modals/ModalTypes';
import discussionService from '@/services/discussionService';
import Discussion from '@/types/Discussions';
import { Product } from '@/types/Content/Product/Product';
import * as Sentry from '@sentry/nextjs';
import ModalStore from './ModalStore';

export type DiscussionStoreHydration = {
  data?: Discussion[];
  tags?: string[];
};

class DiscussionStore {
  isDeleted = false;

  discussionId: string;

  loadingDeletion = false;

  discussionList = [];

  loadingAll = false;

  lastCacheDate: number = null;

  tags = [];

  protected service = discussionService;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setDiscussionList(discussionList: Discussion[]) {
    this.lastCacheDate = Date.now();
    this.discussionList = discussionList;
  }

  async getAllDiscussions(
    newTags: Array<string> = [],
  ): Promise<Array<Product>> {
    if (
      this.discussionList.length &&
      this.lastCacheDate &&
      JSON.stringify(this.tags) === JSON.stringify(newTags) &&
      Date.now() - this.lastCacheDate <= 5 * 60 * 1000
    ) {
      return this.discussionList;
    }
    this.loadingAll = true;
    this.tags = newTags;

    try {
      const params: { tags?: string[] } = {};

      // Temporal fix: only define tags property if there are any, otherwise api response time bloats up.
      if (newTags.length) {
        params.tags = newTags;
      }

      const discussionResponse = await this.service.getDiscussions(params);

      this.setDiscussionList(discussionResponse ?? []);
      return this.discussionList;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    } finally {
      this.loadingAll = false;
    }
  }

  clear() {
    this.isDeleted = false;
    this.discussionId = '';
  }

  requestDelete(id: string) {
    this.discussionId = id;

    ModalStore.showModal(ModalTypes.DiscussionDelete);
  }

  async confirmDelete() {
    ModalStore.showModal(ModalTypes.DiscussionDeleteLoading);

    this.loadingDeletion = true;
    const { error } = await this.service.delete(this.discussionId);
    this.loadingDeletion = false;

    runInAction(() => {
      if (error) {
        ModalStore.showModal(ModalTypes.DiscussionDeleteFail);
      } else {
        this.isDeleted = true;

        ModalStore.closeModal();

        toast('Post deleted successfully!', {
          duration: 3000,
          className: css`
            color: white;
            font-size: 18px;
            padding: 12px 16px;
            border-radius: 10px;
            background: #5fa3a9;
          `,
        });
      }
    });
  }

  hydrate(data: DiscussionStoreHydration) {
    if (data.data) {
      this.setDiscussionList(data.data);
    }
    if (data.tags) {
      this.tags = data.tags;
    }
  }
}

export default new DiscussionStore();
