import React from 'react';
import * as Sentry from '@sentry/nextjs';
import { observer } from 'mobx-react-lite';
import { useGoogleLogin, TokenResponse } from '@react-oauth/google';

import Icon from '@/components/icons/Icon';
import { SocialsButton } from '@/domains/AuthModal/components';
import AuthStore from '@/stores/AuthStore';
import { EOAuthProvider } from '@/types/Auth';
import { useRouter } from 'next/router';

const GoogleLoginComponent = (): JSX.Element => {
  const router = useRouter();
  const { bonus_code: bonus } = router.query || {};

  const onLoginSuccess = async (response: TokenResponse) => {
    try {
      const data = await AuthStore.oauth({
        provider: EOAuthProvider.GOOGLE,
        access_token: response?.access_token,
        bonus_code: bonus as string,
      });

      const { access_token: accessToken, user } = data || {};
      if (accessToken && user) {
        window.dataLayer?.push({
          event: 'login',
          user: {
            id: user.id,
            first_name: user.first_name,
          },
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleLogin = useGoogleLogin({
    onSuccess: onLoginSuccess,
  });

  return (
    <SocialsButton
      variant="outlined"
      startIcon={<Icon iconName="google" />}
      onClick={() => handleLogin()}
    >
      Sign In with Google
    </SocialsButton>
  );
};

export const GoogleLogin = observer(GoogleLoginComponent);
