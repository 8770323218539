import { AuthModalType } from '@/stores/AuthModalStore';
import LogInForm from './LogInForm';
import SignUpForm from './SignUpForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordNotice from './ForgotPasswordNotice';
import ResetPasswordForm from './ResetPasswordForm';
import LetsStartForm from './LetsStartForm';
import TellUsMore from './TellUsMore';
import EmailSent from './EmailSent';

function AuthFormFactory(type: AuthModalType): JSX.Element {
  switch (type) {
    case 'letsStart':
      return <LetsStartForm />;
    case 'tellUsMore':
      return <TellUsMore />;
    case 'login':
      return <LogInForm />;
    case 'signup':
      return <SignUpForm />;
    case 'emailSent':
      return <EmailSent />;
    case 'forgotPassword':
      return <ForgotPasswordForm />;
    case 'forgotPasswordNotice':
      return <ForgotPasswordNotice />;
    case 'resetPassword':
      return <ResetPasswordForm />;
    default:
      return null;
  }
}

export default AuthFormFactory;
