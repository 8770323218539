import { FC } from 'react';
import { observer } from 'mobx-react-lite';

import ModalStore from '@/stores/ModalStore';
import Modal from '@/widgets/Modal';
import { RegisteredModals } from './RegisteredModals';

const RootModal: FC = () => {
  const { isOpen, currentModal, closeModal } = ModalStore;
  const ModalContent: FC = RegisteredModals[currentModal];

  if (!ModalContent) return null;

  return (
    <Modal isOpen={isOpen} handleClose={closeModal}>
      <ModalContent />
    </Modal>
  );
};

export default observer(RootModal);
