import { Typography } from '@mui/material';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { SubscribedGroupsMagnetPropTypes } from './SubscribedGroupsMagnet.types';
import {
  Container,
  Section,
  LinkItem,
  LinkIcon,
  DiscoverCTA,
  LinkContent,
} from './SubscribedGroupsMagnet.styles';
import { IconMap } from './constants/IconMap';
import GroupItem from './GroupItem';

const maxCount = 10;

export function SubscribedGroupsMagnet({
  recentActivity,
  subscribedGroups,
}: SubscribedGroupsMagnetPropTypes): JSX.Element {
  const subscribedGroupsLength = subscribedGroups?.length ?? 0;

  const [showAllVis, setShowAllVis] = useState(
    subscribedGroupsLength > maxCount,
  );

  useEffect(() => {
    setShowAllVis(subscribedGroupsLength > maxCount);
  }, [subscribedGroupsLength]);

  return (
    <div>
      <Container>
        {subscribedGroupsLength && (
          <Section>
            {/* <Typography variant="h3" variantMapping={{ h3: 'h2' }}>
              Your Groups
            </Typography> */}
            {(showAllVis
              ? subscribedGroups?.slice(0, maxCount)
              : subscribedGroups
            )?.map((group) => (
              <GroupItem key={group.slug} group={group} />
            ))}
          </Section>
        )}
        {recentActivity?.length && (
          <Section>
            <Typography variant="h3" variantMapping={{ h3: 'h2' }}>
              Recent
            </Typography>
            {recentActivity?.map((activity) => (
              <Link
                key={activity.slug}
                href={`/discover/${activity.slug}`}
                passHref
              >
                <LinkItem>
                  <LinkIcon>{IconMap[activity.contentType]}</LinkIcon>
                  <LinkContent>{activity.title}</LinkContent>
                </LinkItem>
              </Link>
            ))}
          </Section>
        )}
      </Container>
      {showAllVis && (
        <DiscoverCTA onClick={() => setShowAllVis(false)}>
          Show All Groups
        </DiscoverCTA>
      )}
    </div>
  );
}
