import getPublicConfig from '@/utils/getPublicConfig';

const publicRuntimeConfig = getPublicConfig();
const { AWS_S3_URL } = publicRuntimeConfig;

function getUrlToS3(path: string): string {
  return `${AWS_S3_URL}${path}`;
}

export default getUrlToS3;
