import { ModalTypes } from './ModalTypes';
import DiscussionDeleteModal from './Discussion/DiscussionDeleteModal';
import DiscussionFailureModal from './Discussion/DiscussionFailureModal';
import DiscussionLoadingModal from './Discussion/DiscussionLoadingModal';

export const RegisteredModals = {
  [ModalTypes.DiscussionDelete]: DiscussionDeleteModal,
  [ModalTypes.DiscussionDeleteFail]: DiscussionFailureModal,
  [ModalTypes.DiscussionDeleteLoading]: DiscussionLoadingModal,
};
