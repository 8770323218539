export const getSkeletonAnimation = (): string => `
background-color: #f3f4f8;
position: relative;
overflow: hidden;
border-radius: 0.25rem;
font-color: transparent;
&::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  animation: animation 2s infinite;
  background-image: linear-gradient(
    90deg,
    transparent 0%,
    #e1e1e14b 15%,
    #e1e1e1 50%,
    #e1e1e14b 85%,
    transparent 100%
  );
  content: '';
}

@keyframes animation {
  100% {
    transform: translateX(100%);
  }
}
`;
