import { BlogCategories } from '@/common/constants/blog';
import { ContentType } from '@/common/constants/ContentTypes';
import { LightBulbIcon } from '@/components/icons/LightBulbIcon';
import { ParagraphIcon } from '@/components/icons/ParagraphIcon';
import { SmallPlayIcon } from '@/components/icons/SmallPlayIcon';
import { SmallStarIcon } from '@/components/icons/SmallStarIcon';
import { TagIcon } from '@/components/icons/TagIcon';

export const IconMap = {
  [BlogCategories.ARTICLES]: <ParagraphIcon />,
  [BlogCategories.PRODUCT]: <TagIcon />,
  [BlogCategories.REAL_LIFE_STORIES]: <SmallStarIcon />,
  [BlogCategories.VIDEOS]: <SmallPlayIcon />,
  [ContentType.DISCUSSIONS]: <LightBulbIcon />,
};
